/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import styled from "styled-components";
import Button from "../../components/Button";
import GMDStakingModal from "../../components/GMDStakingModal";
import ZGMDStakingModal from "../../components/zGMDStakingModal";
import GMDStakingModal2 from "../../components/esGMDStakingModal";
import { useState } from "react";
import useTokenInfo from "../../hooks/useTokenInfo";
import { numberWithCommas, figureError } from "../../utils/functions";
import { useAddress, useWeb3Context } from "../../hooks/web3Context";
import useGMDStakingInfo from "../../hooks/useGMDStakingInfo";
import useZGMDStakingInfo from "../../hooks/useZGMDStakingInfo";
import { getGMDStakingContract, getZGMDStakingContract } from "../../utils/contracts";
import { ethers } from "ethers";
import Topbar from "../../components/Topbar/Topbar";
import TopbarDapp from "../../components/Topbar2/Topbar";

const Stake = ({ setNotification }) => {
  const { price, pool } = useTokenInfo();
  const {
    allowance,
    esGMDallowance,
    esGMDbalance,
    balance,
    totalStaked,
    totalesGMDStaked,
    withdrawable,
    apy,
    apyEs,
    reward,
    rewardEs,
    RPreward,
    RPrewardEs,
    stakedRP,
    stakedRPEs,
    totalRP,
    totalRPEs,
    stakedAmount,
    stakedEsAmount,
    totalRevenue,
    boostPercent,
    boostPercentEs,
    fetchStakingData,
    fetchStakingAccountData,
    VestAmount,
    ZGNDbalance,
  } = useGMDStakingInfo();

  const {

    totalStaked2,
    totalesGMDStaked2,
    withdrawable2,
    apy2,
    apyEs2,
    reward2,
    rewardEs2,
    RPreward2,
    RPrewardEs2,
    stakedRP2,
    stakedRPEs2,
    totalRP2,
    totalRPEs2,
    stakedAmount2,
    stakedEsAmount2,
    totalRevenue2,
    boostPercent2,
    boostPercentEs2,
    fetchStakingData2,
    fetchStakingAccountData2,
    VestAmount2,
    
    zGNDstakedAmount,
  } = useZGMDStakingInfo();

  const account = useAddress();
  const { connect, provider } = useWeb3Context();

  function onConnect() {
    connect().then((msg) => {
      if (msg.type === "error") {
        setNotification(msg);
      }
    });
  }

  const [open, setOpen] = useState(false);
  const [type, setType] = useState(1);
  const [open2, setOpen2] = useState(false);
  const [type2, setType2] = useState(1);
  const [curIndex, setCurIndex] = useState(0);
  const [amount, setAmount] = useState(0);
  const [maxPressed, setMaxPresssed] = useState(false);
  const [pending, setPending] = useState(false);
  const [amount2, setAmount2] = useState(0);
  const [maxPressed2, setMaxPresssed2] = useState(false);
  const [pending2, setPending2] = useState(false);
  const [isWETH, setIsWETH] = useState(false);

  const decimals = [6, 18, 8, 18];

  const getBalance = (amount, decimals = 18) => {
    return numberWithCommas((amount / Math.pow(10, decimals)).toFixed(5));
  };
  const getBalanceUSD = (amount, price, decimals = 18) => {
    return numberWithCommas(
      ((price * amount) / Math.pow(10, decimals)).toFixed(5)
    );
  };
  const getStakedUSD = price * (totalStaked + totalRP);
  const getStakedUSD2 = price * (totalStaked2 + totalRP2);

  const onConfirm = async () => {
    setPending(true);
    try {
      const stakingContract = getGMDStakingContract(provider.getSigner());
      let estimateGas, tx;
      if (type === 1) {
        estimateGas = await stakingContract.estimateGas.deposit(
          0,
          maxPressed ? balance : ethers.utils.parseEther(amount)
        );
      } else {
        estimateGas = await stakingContract.estimateGas.withdraw(
          0,
          maxPressed ? stakedAmount : ethers.utils.parseEther(amount)
        );
      }
      console.log(estimateGas.toString());

      const ttx = {
        gasLimit: Math.ceil(estimateGas.toString() * 1.2),
      };
      if (type === 1) {
        tx = await stakingContract.deposit(
          0,
          maxPressed ? balance : ethers.utils.parseEther(amount),
          ttx
        );
      } else {
        tx = await stakingContract.withdraw(
          0,
          maxPressed ? stakedAmount : ethers.utils.parseEther(amount),
          ttx
        );
      }
      await tx.wait();
      fetchStakingAccountData();
    } catch (error) {
      console.log(error);
      figureError(error, setNotification);
    }
    setPending(false);
  };

  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const onConfirm2 = async () => {
    setPending2(true);
    try {
      const stakingContract = getZGMDStakingContract(provider.getSigner());
      let estimateGas, tx;
      if (type2 === 1) {
        estimateGas = await stakingContract.estimateGas.deposit(
          0,
          maxPressed2 ? ZGNDbalance : ethers.utils.parseEther(amount2)
        );
      } else {
        estimateGas = await stakingContract.estimateGas.withdraw(
          0,
          maxPressed2 ? stakedAmount2: ethers.utils.parseEther(amount2)
        );
      }
      console.log(estimateGas.toString());

      const ttx = {
        gasLimit: Math.ceil(estimateGas.toString() * 1.2),
      };
      if (type2 === 1) {
        tx = await stakingContract.deposit(
          0,
          maxPressed2 ? ZGNDbalance : ethers.utils.parseEther(amount2),
          ttx
        );
      } else {
        tx = await stakingContract.withdraw(
          0,
          maxPressed2 ? stakedAmount2 : ethers.utils.parseEther(amount2),
          ttx
        );
      }
      await tx.wait();
      fetchStakingAccountData();
    } catch (error) {
      console.log(error);
      figureError(error, setNotification);
    }
    setPending(false);
  };

  const onClaim = async () => {
    setPending(true);
    try {
      const stakingContract = getGMDStakingContract(provider.getSigner());
      let estimateGas, tx;
      estimateGas = await stakingContract.estimateGas.deposit(0, 0);
      console.log(estimateGas.toString());

      const ttx = {
        gasLimit: Math.ceil(estimateGas.toString() * 1.2),
      };
      tx = await stakingContract.deposit(0, 0, ttx);
      await tx.wait();
      fetchStakingAccountData();
    } catch (error) {
      console.log(error);
      figureError(error, setNotification);
    }
    setPending(false);
  };
  const onClaim2 = async () => {
    setPending(true);
    try {
      const stakingContract = getZGMDStakingContract(provider.getSigner());
      let estimateGas, tx;
      estimateGas = await stakingContract.estimateGas.deposit(0, 0);
      console.log(estimateGas.toString());

      const ttx = {
        gasLimit: Math.ceil(estimateGas.toString() * 1.2),
      };
      tx = await stakingContract.deposit(0, 0, ttx);
      await tx.wait();
      fetchStakingAccountData();
    } catch (error) {
      console.log(error);
      figureError(error, setNotification);
    }
    setPending(false);
  };

  return (
    <div>
      <TopbarDapp
        color="white"
        connectEnabled={true}
        style={{ paddingTop: "24px" }}
      />
      <StyledContainer>
        <GMDStakingModal
          open={open}
          setOpen={setOpen}
          maxPressed={maxPressed}
          setMaxPressed={setMaxPresssed}
          onConfirm={onConfirm}
          pending={pending}
          setPending={setPending}
          setNotification={setNotification}
          max={
            type === 1
              ? balance / Math.pow(10, 18)
              : stakedAmount / Math.pow(10, 18)
          }
          amount={amount}
          RPamount={stakedRP}
          VestAmount={VestAmount}
          setAmount={setAmount}
          type={type}
        />
        <ZGMDStakingModal
          open={open2}
          setOpen={setOpen2}
          maxPressed={maxPressed2}
          setMaxPressed={setMaxPresssed2}
          onConfirm={onConfirm2}
          pending={pending2}
          setPending={setPending2}
          setNotification={setNotification}
          max={
            type2 === 1
              ? ZGNDbalance / Math.pow(10, 18)
              : stakedAmount2/ Math.pow(10, 18)
          }
          amount={amount2}
          RPamount= {0}
          VestAmount={VestAmount}
          setAmount={setAmount2}
          type={type2}
        />

        <Box fontSize={"34px"} mb={"0px"} fontWeight={"bold"}>
          Stake xGND
        </Box>
        <Box fontSize={"18px"} fontWeight={"400"} mb={"10px"}>
          Withdraw your xGND from our old stake pool{" "}
          <a
            href={"https://622ed179.gnd-frontend.pages.dev/stake"}
            target={"_blank"}
          >
            {" "}
            <Button
              style={{ fontWeight: "bold", fontSize: "20px", color: "red" }}
              type={"primary"}
              width={"100px"}
              height={"56px"}
            >
              HERE
            </Button>
          </a>
        </Box>
        <Button
          type={"secondary"}
          width={"120px"}
          height={"30px"}
          onClick={toggleDetails}
          mb={"10px"}
        >
          {showDetails ? "Hide" : "Read more"}
        </Button>

        {showDetails && (
          <>
            <Box fontSize={"18px"} fontWeight={"400"} mt={"2px"}>
              1. 1 RP = 1 staking power of 1 xGND. RPs boost your yields on
              current staked xGND.
            </Box>
            <Box fontSize={"18px"} fontWeight={"400"} mt={"2px"}>
              2. Compound your Reward Points(RP) to boost your ETH APR.
            </Box>
            <Box fontSize={"18px"} fontWeight={"200"} mt={"2px"}>
              3. Unstaking burns all of your non-vesting RP.
            </Box>
          </>
        )}

        <Box fontSize={"18px"} fontWeight={"200"} mt={"15px"}>
          Note: There is a 0.5% deposit fee to burn xGND
        </Box>

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          marginTop={"20px"}
          width={"90%"}
        >
          <Panel>
            <PanelHeader justifyContent={"center"}>
              <Box>
                <CoinSVG
                  justifyContent={"center"}
                  style={{
                    background: `url(/icons/58.png)`,
                    backgroundSize: "100% 100%",
                    width: "75px",
                    height: "70px",
                  }}
                />
                <Box textAlign={"center"}>xGND</Box>
              </Box>
            </PanelHeader>
            <Divider />
            <PanelBody>
              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Price</Box>
                <Box>${price.toFixed(2)}</Box>
              </Box>
              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Wallet</Box>
                <Box>
                  {getBalance(balance)} xGND (${getBalanceUSD(balance, price)})
                </Box>
              </Box>
              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Base APR</Box>
                <Box>
                  {(
                    (apy * pool[1].price * 3600 * 24 * 365 * 100) /
                    getStakedUSD
                  ).toFixed(2)}
                  %
                </Box>
              </Box>
              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Your boosted APR</Box>
                <Box>
                  {(
                    (apy * pool[1].price * 3600 * 24 * 365 * boostPercent) /
                    getStakedUSD
                  ).toFixed(2)}
                  %
                </Box>
              </Box>
            </PanelBody>
            <Divider />
            <PanelBody
              height={"132px"}
              justifyContent={"center"}
              display={"flex"}
              flexDirection={"column"}
            >
              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>User Staked</Box>
                <Box>
                  {getBalance(stakedAmount)} xGND ($
                  {getBalanceUSD(stakedAmount, price)})
                </Box>
              </Box>

              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Users RP</Box>
                <Box>{getBalance(stakedRP - VestAmount)} RP</Box>
              </Box>

              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Users Vesting RP</Box>
                <Box>
                  {getBalance(VestAmount)} RP($
                  {getBalanceUSD(VestAmount, price)})
                </Box>
              </Box>

              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Rewards</Box>
                <Box>
                  {getBalance(reward, 0)} WETH ($
                  {getBalanceUSD(reward, pool[1].price, 0)})
                </Box>
              </Box>

              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>RP Earned</Box>
                <Box>{getBalance(RPreward, 0)} RP</Box>
              </Box>
            </PanelBody>
            <Divider />
            <PanelBody>
              <Box alignItems={"center"} mb={"5px!important"}>
                <Box color={"rgba(255, 255, 255, 0.7)"}>
                  Total Staked in Pool
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Box>
                    {getBalance(totalStaked, 0)} xGND ($
                    {getBalanceUSD(totalStaked, price, 0)})
                  </Box>
                </Box>
              </Box>
              <Box alignItems={"center"} mb={"20px!important"}>
                <Box color={"rgba(255, 255, 255, 0.7)"}>
                  Total Protocol Revenue
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Box>
                    {getBalance(totalRevenue, 0)} ETH ($
                    {getBalanceUSD(totalRevenue, pool[1].price, 0)})
                  </Box>
                </Box>
              </Box>
            </PanelBody>
            <Divider />

            <Box display={"flex"} justifyContent={"center"}>
              {!account ? (
                <Button
                  type={"primary"}
                  width={"136px"}
                  height={"36px"}
                  onClick={() => onConnect()}
                >
                  Connect Wallet
                </Button>
              ) : (
                <>
                  <Button
                    type={"primary"}
                    width={"80px"}
                    height={"36px"}
                    disabled={pending}
                    onClick={() => {
                      setOpen(true);
                      setType(1);
                    }}
                  >
                    Stake
                  </Button>
                  <Button
                    type={"primary"}
                    width={"80px"}
                    height={"36px"}
                    disabled={pending || !withdrawable || !Number(stakedAmount)}
                    onClick={() => {
                      setOpen(true);
                      setType(2);
                    }}
                  >
                    Unstake
                  </Button>
                  <Button
                    type={"primary"}
                    width={"200px"}
                    height={"36px"}
                    disabled={pending || !Number(reward) & !Number(RPreward)}
                    onClick={() => {
                      onClaim();
                    }}
                  >
                    Claim and Compound RP
                  </Button>
                </>
              )}
            </Box>
          </Panel>
          <Panel>
            <PanelHeader justifyContent={"center"}>
              <Box>
                <CoinSVG
                  justifyContent={"center"}
                  style={{
                    background: `url(/icons/57.png)`,
                    backgroundSize: "100% 100%",
                    width: "75px",
                    height: "70px",
                  }}
                />
                <Box textAlign={"center"}>zGND</Box>
              </Box>
            </PanelHeader>
            <Divider />
            <PanelBody>
              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Deposit fee</Box>
                <Box>0.5%</Box>
              </Box>
              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Wallet</Box>
                <Box>
                  {getBalance(ZGNDbalance)} zGND (${getBalanceUSD(ZGNDbalance, price)})
                </Box>
              </Box>
              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Base APR</Box>
                <Box>
                  {(
                    (apy2 * pool[1].price * 3600 * 24 * 365 * 100) /
                    getStakedUSD2
                  ).toFixed(2)}
                  %
                </Box>
              </Box>
              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Your boosted APR</Box>
                <Box>
                  {(
                    (apy2 * pool[1].price * 3600 * 24 * 365 * boostPercent2) /
                    getStakedUSD2
                  ).toFixed(2)}
                  %
                </Box>
              </Box>
            </PanelBody>
            <Divider />
            <PanelBody
              height={"132px"}
              justifyContent={"center"}
              display={"flex"}
              flexDirection={"column"}
            >
              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>User Staked</Box>
                <Box>
                  {getBalance(stakedAmount2)} zGND ($
                  {getBalanceUSD(stakedAmount2, price)})
                </Box>
              </Box>

              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Users RP</Box>
                <Box>{getBalance(stakedRP2 - VestAmount2)} RP</Box>
              </Box>

              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Users Vesting RP</Box>
                <Box>
                  {getBalance(VestAmount2)} RP($
                  {getBalanceUSD(VestAmount2, price)})
                </Box>
              </Box>

              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Rewards</Box>
                <Box>
                  {getBalance(reward2, 0)} WETH ($
                  {getBalanceUSD(reward2, pool[1].price, 0)})
                </Box>
              </Box>

              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>RP Earned</Box>
                <Box>{getBalance(RPreward2, 0)} RP</Box>
              </Box>
            </PanelBody>
            <Divider />
            <PanelBody>
              <Box alignItems={"center"} mb={"5px!important"}>
                <Box color={"rgba(255, 255, 255, 0.7)"}>
                  Total Staked in Pool
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Box>
                    {getBalance(totalStaked2, 0)} zGND ($
                    {getBalanceUSD(totalStaked2, price, 0)})
                  </Box>
                </Box>
              </Box>
              <Box alignItems={"center"} mb={"20px!important"}>
                <Box color={"rgba(255, 255, 255, 0.7)"}>
                  Total Protocol Revenue
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Box>
                    {getBalance(totalRevenue2, 0)} ETH ($
                    {getBalanceUSD(totalRevenue2, pool[1].price, 0)})
                  </Box>
                </Box>
              </Box>
            </PanelBody>
            <Divider />

            <Box display={"flex"} justifyContent={"center"}>
              {!account ? (
                <Button
                  type={"primary"}
                  width={"136px"}
                  height={"36px"}
                  onClick={() => onConnect()}
                >
                  Connect Wallet
                </Button>
              ) : (
                <>
                  <Button
                    type={"primary"}
                    width={"80px"}
                    height={"36px"}
                    disabled={pending2}
                    onClick={() => {
                      setOpen2(true);
                      setType2(1);
                    }}
                  >
                    Stake
                  </Button>
                  <Button
                    type={"primary"}
                    width={"80px"}
                    height={"36px"}
                    disabled={pending2 || !withdrawable2 || !Number(stakedAmount2)}
                    onClick={() => {
                      setOpen2(true);
                      setType2(2);
                    }}
                  >
                    Unstake
                  </Button>
                  <Button
                    type={"primary"}
                    width={"200px"}
                    height={"36px"}
                    disabled={pending || !Number(reward) & !Number(RPreward)}
                    onClick={() => {
                      onClaim2();
                    }}
                  >
                    Claim and Compound RP
                  </Button>
                </>
              )}
            </Box>
          </Panel>
        </Box>
      </StyledContainer>
    </div>
  );
};

// const CoinSVG = styled(Box)`
//   width: 40px;
//   height: 40px;
// `;

// const Panel = styled(Box)`
//   padding: 15px 15px 18px;
//   border: 1px solid #61b8b5;
//   border-radius: 4px;
//   font-size: 15px;
//   background: transparent;
//   width: 100%;
//   max-width: calc(50% - 8px);
//   @media screen and (max-width: 900px) {
//     max-width: 100%;
//   }
//   margin-bottom: 15px;
// `;
// const PanelHeader = styled(Box)`
//   font-size: 16px;
//   line-height: 21px;
//   font-weight: normal;
//   letter-spacing: 0px;
//   color: #ffffff;
//   margin-bottom: 15px;
//   display: flex;
//   align-items: center;
// `;

// const PanelBody = styled(Box)`
//   > div {
//     display: flex;
//     justify-content: space-between;
//     line-height: 130%;
//     margin-top: 8px;
//   }
//   > div:first-child {
//     margin: 0;
//   }
// `;

// const Divider = styled(Box)`
//   height: 1px;
//   background: #61b8b5;
//   margin: 10.5px -15px;
// `;

// const StyledContainer = styled(Box)`
//   display: flex;
//   flex-direction: column;
//   padding-top: 0px;
//   max-width: 70%;
//   padding-left: 32px;
//   padding-right: 32px;
//   margin: 0 auto;
//   letter-spacing: 0.5px;
//   padding-bottom: 100px;
//   @media screen and (max-width: 600px) {
//     padding-left: 22px;
//     padding-right: 22px;
//   }
// `;

const CoinSVG = styled(Box)`
  width: 40px;
  height: 40px;
`;

const Panel = styled(Box)`
  padding: 15px 15px 18px;
  border: 1px solid #61b8b5;
  border-radius: 4px;
  font-size: 15px;
  background: transparent;
  width: 100%;
  max-width: calc(50% - 8px);
  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
  margin-bottom: 15px;
`;

const PanelHeader = styled(Box)`
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
`;

const PanelBody = styled(Box)`
  > div {
    display: flex;
    justify-content: space-between;
    line-height: 130%;
    margin-top: 8px;
  }
  > div:first-child {
    margin: 0;
  }
`;

const Divider = styled(Box)`
  height: 1px;
  background: #61b8b5;
  margin: 10.5px -15px;
`;

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  max-width: 70%;
  padding-left: 32px;
  padding-right: 32px;
  margin: 0 auto;
  letter-spacing: 0.5px;
  padding-bottom: 35px;
  @media screen and (max-width: 600px) {
    padding-left: 22px;
    padding-right: 22px;
    max-width: 100%;
  }
`;

export default Stake;

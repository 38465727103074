import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useWeb3Context } from "./hooks/web3Context";
import Footer from "./components/Footer";
import { Box, TextareaAutosize } from "@mui/material";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Stake from "./pages/Stake";
import Vest from "./pages/Vest";
import ZGND from "./pages/zGND";
import Liquidity from "./pages/Liquidity";
import { GMDStakingInfoProvider } from "./hooks/useGMDStakingInfo";
import { ZGMDStakingInfoProvider } from "./hooks/useZGMDStakingInfo";
import { TokenInfoProvider } from "./hooks/useTokenInfo";
import { VestInfoProvider } from "./hooks/VestContext";
import { GNDInfoProvider } from "./hooks/GNDinfo";
import { GMUSDInfoProvider } from "./hooks/gmUSDinfo";
import { PoolsInfoProvider } from "./hooks/usePoolsInfo";
function App() {
  const { connect, hasCachedProvider } = useWeb3Context();
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (hasCachedProvider()) {
      // then user DOES have a wallet
      connect().then((msg) => {
        if (msg.type === "error") {
          setNotification(msg);
        }
      });
    } else {
      // then user DOES NOT have a wallet
    }
    // We want to ensure that we are storing the UTM parameters for later, even if the user follows links
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Box position="relative">
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route
              path="/"
              element={
                <PoolsInfoProvider>
                  <GMDStakingInfoProvider>
                    {" "}
                    <Home />
                  </GMDStakingInfoProvider>
                </PoolsInfoProvider>
              }
            />
            <Route
              path="/dashboard"
              element={
                <GMDStakingInfoProvider>
                  <GMUSDInfoProvider>
                    <GNDInfoProvider>
                      <PoolsInfoProvider>
                        <TokenInfoProvider>
                          <Dashboard setNotification={setNotification} />
                        </TokenInfoProvider>
                      </PoolsInfoProvider>
                    </GNDInfoProvider>
                  </GMUSDInfoProvider>
                </GMDStakingInfoProvider>
              }
            />
            <Route
              path="/stake"
              element={
                <TokenInfoProvider>
                  <GMDStakingInfoProvider>
                    <ZGMDStakingInfoProvider>
                      <Stake setNotification={setNotification} />
                    </ZGMDStakingInfoProvider>
                  </GMDStakingInfoProvider>
                </TokenInfoProvider>
              }
            />
            <Route
              path="/vest"
              element={
                <TokenInfoProvider>
                  <GMDStakingInfoProvider>
                    <VestInfoProvider>
                      <Vest setNotification={setNotification} />
                    </VestInfoProvider>
                  </GMDStakingInfoProvider>
                </TokenInfoProvider>
              }
            />
            <Route
              path="/liquidity"
              element={
                <PoolsInfoProvider>
                  <Liquidity setNotification={setNotification} />
                </PoolsInfoProvider>
              }
            />
            <Route
              path="/zGND"
              element={
                <TokenInfoProvider>
                  <GMDStakingInfoProvider>
                    <VestInfoProvider>
                      <ZGND setNotification={setNotification} />
                    </VestInfoProvider>
                  </GMDStakingInfoProvider>
                </TokenInfoProvider>
              }
            />
          </Routes>
        </Box>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

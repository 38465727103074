/* eslint-disable jsx-a11y/alt-text */
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import "../../styles/menu.css";
import { Box } from "@mui/material";
import styled from "styled-components";
import { useAddress, useWeb3Context } from "../../hooks/web3Context";
import Button from "../Button";

const Hamburger = ({
  activePage,
  setActivePage,
  color,
  connectEnabled,
  setNotification,
}) => {
  const menuRef = useRef(null);

  const { connect, disconnect } = useWeb3Context();
  function onConnect() {
    connect().then((msg) => {
      if (msg.type === "error") {
        setNotification(msg);
      }
    });
  }
  const account = useAddress();

  const ellipsis = account
    ? account.slice(0, 6) +
      "..." +
      account.substring(account.length - 5, account.length)
    : "Connect Wallet";

  useEffect(() => {
    document.addEventListener("mouseup", function (event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        let form = document.getElementById("check");
        if (form) form.checked = false;
      }
    });
  }, []);

  const menus = ["Dashboard", "Stake", "Liquidity", "Vest"];

  return (
    <nav role="navigation" style={{ background: "red" }}>
      <div id="menuToggle" ref={menuRef}>
        {/* A fake / hidden checkbox is used as click reciever,
    so you can use the :checked selector on it. */}

        <input
          type="checkbox"
          id="check"
          style={{
            width: "80px",
            height: "60px",
            marginTop: "-20px",
            marginRight: "-20px",
          }}
        />

        {/* Some spans to act as a hamburger.
    
    They are acting like a real hamburger,
    not that McDonalds stuff. */}

        <span style={{ backgroundColor: color }}></span>
        <span style={{ backgroundColor: color }}></span>
        <span style={{ backgroundColor: color }}></span>
        <span style={{ backgroundColor: color }}></span>

        {/* Too bad the menu has to be inside of the button
    but hey, it's pure CSS magic. */}
        <Menu id="menu">
          <StyledContainer>
            <Box
              display={"flex"}
              minWidth={"70px"}
              minHeight={"70px"}
              maxWidth={"70px"}
              maxHeight={"70px"}
            >
              {/* <img src={"/logo.png"} width={"100%"} height={"100%"} alt={""} /> */}
            </Box>
          </StyledContainer>
          <Box style={{ marginTop: "20px" }}>
            <Menus active={activePage}>
              {menus.map((data, i) => {
                return (
                  <MenuItem
                    key={i}
                    to={`/${data.replace(" ", "").toLowerCase()}`}
                    index={i}
                    onClick={() => {
                      let form = document.getElementById("check");
                      if (form) form.checked = false;
                      setActivePage(i + 1);
                    }}
                  >
                    {data}
                  </MenuItem>
                );
              })}

              <MenuItem to={`https://farm.gndprotocol.com`} target="blank">
                Farm
              </MenuItem>

              {connectEnabled ? (
                <Dbox>
                  <ul className="navbar-nav mt-2 mt-lg-0">
                    <li className="nav-item mr-2 mb-3 mb-lg-0">
                      <Button
                        type={"primary"}
                        width={"162px"}
                        height={"36px"}
                        onClick={() => (account ? disconnect() : onConnect())}
                        account={account}
                      >
                        {ellipsis}
                      </Button>
                    </li>
                  </ul>
                </Dbox>
              ) : (
                <div></div>
              )}

              {/* <Dbox>
                <a
                      
                      href={`http://twitter.com/intent/tweet?text=This%20is%20my%20tweet%20to%20acquire%20early%20access%20to%20@GMDprotocol%20pseudo-delta-neutral%20USDC,ETH,and%20BTC%20vaults%20built%20on%20top%20of%20$GLP%20and%20mint%20$GMD,%20the%20protocol's%20governance%20and%20real%20yield%20token.%20visit%3A&url=http%3A%2F%2Fgmdprotocol.com%20for%20more%20information.`}
                      target ="blank"
                    >
                      Whitelist
                </a>
              </Dbox> */}
            </Menus>
          </Box>
        </Menu>
      </div>
    </nav>
  );
};

const MenuItem = styled(Link)`
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: white !important;
  transition: unset;
  padding: 15px 16px;
  text-decoration: none;
  display: block;
  :hover {
    background: #61b8b5;
    color: white !important;
  }
  transition: unset !important;
`;
const Menus = styled(Box)`
  > a:nth-child(${({ active }) => active}) {
    background: black;
    color: white !important;
  }
`;

const StyledContainer = styled(Box)``;
const Dbox = styled(Box)`
  display: flex;
  > a {
    padding: 18px 17px;
    display: block;
    color: white;
    font-size: 15px;
    line-height: 18px;
    font-weight: normal;
    background: black;
  }
`;

const Menu = styled.ul`
  position: relative;
  overflow: hidden;
`;

export default Hamburger;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  getMulticallContract,
  getTokenContract,
  multicall,
} from "../utils/contracts";
import ValutABI from "../abis/ValutABI.json";
import ERC20ABI from "../abis/ERC20ABI.json";
import MINTABI from "../abis/MintABI.json";
import BUYBACKABI from "../abis/BUYBACKABI.json";
import { xGND_ADDR, GND_ADDR, BUYBACK_ADDR } from "../abis/address";
import { useAddress } from "./web3Context";

import { ethers } from "ethers";

const defaultVal = {
  GNDdata: {},
  allowance: false,
  buyback: 0,
  tvl: 0,

  fetchGNDdata: () => {},
};

export const GNDinfo = React.createContext(defaultVal);

export default function GNDInfo() {
  return React.useContext(GNDinfo);
}
let dataid = null,
  accountid = null;

export function GNDInfoProvider({ children }) {
  const account = useAddress();
  const [GNDdata, setGNDdata] = useState({
    totalSupply: 0,
    totalXGNDSupply: 0,
    burn: 0,
    GNDburn: 0,
  });
  const [buyback, setBuyback] = useState(0);

  async function fetchGNDdata() {
    try {
      let calls = [
        {
          address: GND_ADDR,
          name: "totalSupply",
          params: [],
        },
      ];
      let calls2 = [
        {
          address: xGND_ADDR,
          name: "totalSupply",
          params: [],
        },
      ];

      let calls3 = [
        {
          address: BUYBACK_ADDR,
          name: "totalBuyBack",
          params: [],
        },
      ];

      const burnaddress = "0x000000000000000000000000000000000000dEaD";

      let calls4 = [
        {
          address: xGND_ADDR,
          name: "balanceOf",
          params: [burnaddress],
        },

        {
          address: GND_ADDR,
          name: "balanceOf",
          params: [burnaddress],
        },
      ];

      const supply = await multicall(MINTABI, calls);
      const supply2 = await multicall(MINTABI, calls2);
      const buyback_amount = await multicall(BUYBACKABI, calls3);
      const burndata = await multicall(MINTABI, calls4);
      console.log(buyback_amount[0]);
      setGNDdata({
        totalSupply: supply[0],
        totalXGNDSupply: supply2[0],
        burn: burndata[0] / Math.pow(10, 18) + burndata[1] / Math.pow(10, 18),
        GNDburn: burndata[0] / Math.pow(10, 18),
      });
      setBuyback(buyback_amount[0] / Math.pow(10, 18));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchGNDdata();

    if (accountid) clearInterval(accountid);
    accountid = setInterval(() => {
      fetchGNDdata();
    }, 20000);
  }, [account]);

  return (
    <GNDinfo.Provider
      value={{
        GNDdata,
        buyback,
        fetchGNDdata,
      }}
      children={children}
    />
  );
}

import { ethers } from "ethers";
import {
  GMD_STAKING_ADDR,
  xGND_ADDR,
  MULTICALL_ADDR,
  NEWVAULT_ADDR,
  PRICE_ADDR,
  VAULT_ADDR,
  UNIV3_ADDR,
  UNIV3_LP_ADDR,
  CASHBACK_ADDR,
  OTC_ADDR,
  ZGMD_ADDR,
  ZGMD_STAKING_ADDR,
  SWAPZGND_ADDR,
} from "../abis/address";
import MultiCallABI from "../abis/MultiCallABI.json";
import ERC20ABI from "../abis/ERC20ABI.json";
import VaultABI from "../abis/ValutABI.json";
import NewVaultABI from "../abis/NewVaultABI.json";
import PriceABI from "../abis/PriceABI.json";
import MintABI from "../abis/MintABI.json";
import GMDStakingABI from "../abis/GMDStakingABI.json";
import UniswapMathABI from "../abis/UniswapMathABI.json";
import UniV3LPABI from "../abis/UniV3LPABI.json";
import CASHBACKABI from "../abis/CASHBACKABI.json";
import OTCABI from "../abis/OTCABI.json";
import ZGNDABI from "../abis/ZGNDABI.json";
import SWAPZGNDABI from "../abis/SWAPZGNDABI.json";

export const RPC_ENDPOINT = "https://arb1.arbitrum.io/rpc";

export const getContract = (abi, address, signer) => {
  const simpleRpcProvider = new ethers.providers.JsonRpcProvider(RPC_ENDPOINT);
  const signerOrProvider = signer ?? simpleRpcProvider;
  return new ethers.Contract(address, abi, signerOrProvider);
};
export const getTokenContract = (address, signer) => {
  return getContract(ERC20ABI, address, signer);
};
export const getVaultContract = (signer) => {
  return getContract(VaultABI, VAULT_ADDR, signer);
};

export const getNewVaultContract = (signer) => {
  return getContract(NewVaultABI, NEWVAULT_ADDR, signer);
};

export const getPriceContract = (signer) => {
  return getContract(PriceABI, PRICE_ADDR, signer);
};

export const getXGNDContract = (signer) => {
  return getContract(MintABI, xGND_ADDR, signer);
};

export const getZGNDContract = (signer) => {
  return getContract(ZGNDABI, ZGMD_ADDR, signer);
};

export const getSwapZGNDContract = (signer) => {
  return getContract(SWAPZGNDABI, SWAPZGND_ADDR, signer);
};


export const getCashBackContract = (signer) => {
  return getContract(CASHBACKABI, CASHBACK_ADDR, signer);
};

export const getGMDStakingContract = (signer) => {
  return getContract(GMDStakingABI, GMD_STAKING_ADDR, signer);
};

export const getZGMDStakingContract = (signer) => {
  return getContract(GMDStakingABI, ZGMD_STAKING_ADDR, signer);
};

export const getMulticallContract = (signer) => {
  return getContract(MultiCallABI, MULTICALL_ADDR, signer);
};

export const getUniswapMathContract = (signer) => {
  return getContract(UniswapMathABI, UNIV3_ADDR, signer);
};

export const getUniV3LPContract = (signer) => {
  return getContract(UniV3LPABI, UNIV3_LP_ADDR, signer);
};

export const getOTCContract = (signer) => {
  return getContract(OTCABI, OTC_ADDR, signer);
};

export const multicall = async (abi, calls) => {
  try {
    const itf = new ethers.utils.Interface(abi);
    const multi = getMulticallContract();
    const calldata = calls.map((call) => [
      call.address.toLowerCase(),
      itf.encodeFunctionData(call.name, call.params),
    ]);

    const { returnData } = await multi.aggregate(calldata);
    const res = returnData.map((call, i) =>
      itf.decodeFunctionResult(calls[i].name, call)
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { getTokenContract, multicall } from "../utils/contracts";
import PriceABI from "../abis/PriceABI.json";
import ValutABI from "../abis/ValutABI.json";
import NewVaultABI from "../abis/NewVaultABI.json";
import {
  BTC_ADDR,
  ETH_ADDR,
  PRICE_ADDR,
  USDC_ADDR,
  VAULT_ADDR,
  NEWVAULT_ADDR,
  GMD_ADDR,
  esGMD_ADDR,
} from "../abis/address";
import axios from "axios";
import _ from "lodash";
import { Token } from "@uniswap/sdk-core";
import IUniswapV3Pool from "../abis/IUniswapV3Pool.json";

import { Pool } from "@uniswap/v3-sdk/";
import { ethers } from "ethers";

export const getUniv3Unclaimedfees = async (treasuryAddr, gmusdPrice) => {
  const res = await axios.get(
    `https://api.covalenthq.com/v1/42161/address/${treasuryAddr}/balances_v2/?quote-currency=USD&format=JSON&nft=false&no-nft-fetch=false&key=cqt_rQJvkVYWdbWhYGkFRhHyCVTVJ9G7`
  );
  const val = res.data.data.items;
  let price = 0;
  _.map(val, (each) => {
    if (
      each.contract_ticker_symbol == "gmUSD" ||
      each.contract_ticker_symbol == "gDAI" ||
      each.contract_ticker_symbol == "gmdUSDC"
    ) {
      price +=
        (parseInt(each.balance) / Math.pow(10, each.contract_decimals)) *
        gmusdPrice;
    }
    price += each.quote;
  });
  console.log(price);
  return price;
};

const defaultVal = {
  fetchData: () => {},
  fetchData2: () => {},
  totalUSDValuts: 0,
  totalUSDValuts2: 0,
  GLPinVault: 0,
  GLPinVault2: 0,
  GLPPrice: 0,
  totalFees: 0,
  GLPbackingNeeded: 0,
  GLPbackingNeeded2: 0,
  price: 0,
  liquidity: 0,
  mintedTokens: 0,
  lockedTokens: 0,
  treasury: 0,
  pool: [{}, {}, {}],
};

export const TokenInfoContext = React.createContext(defaultVal);

export default function useTokenInfo() {
  return React.useContext(TokenInfoContext);
}
let dataid = null;

const provider = new ethers.providers.JsonRpcProvider(
  "https://arb1.arbitrum.io/rpc"
);

// pool address
const poolAddress = "0x99db8DBA7b30aB3C6447C4388d81dcc27C5A8b61";
const poolAddress2 = "0x3bB5690832AdED8D217D257583c189C7f0093fe9";

const poolContract = new ethers.Contract(poolAddress, IUniswapV3Pool, provider);
const poolContract2 = new ethers.Contract(
  poolAddress2,
  IUniswapV3Pool,
  provider
);

export function TokenInfoProvider({ children }) {
  const [totalUSDValuts, setTotalUSDValuts] = useState(0);
  const [totalUSDValuts2, setTotalUSDValuts2] = useState(0);
  const [GLPinVault, setGLPInValut] = useState(0);
  const [GLPinVault2, setGLPInValut2] = useState(0);
  const [GLPPrice, setGLPPrice] = useState(0);
  const [totalFees, setTotalFees] = useState(0);
  const [GLPbackingNeeded, setGLPBackingNeeded] = useState(0);
  const [GLPbackingNeeded2, setGLPBackingNeeded2] = useState(0);
  const [price, setPrice] = useState(0);
  const [gmusdPrice, setPrice2] = useState(0);
  const [liquidity, setLiquidity] = useState(0);
  const [liquidity2, setLiquidity2] = useState(0);
  const [liquidity3, setLiquidity3] = useState(0);
  const [liquidity4, setLiquidity4] = useState(0);
  const [treasury, setTreasury] = useState(0);
  const [mintedTokens, setMintedTokens] = useState(0);
  const [lockedTokens, setLockTokens] = useState(0);

  const [pool, setPool] = useState([
    {
      price: 0,
      ratio: 0,
      pool: 0,
      weight: 0,
      apr: 0,
      totalStaked: 0,
      vaultcap: 0,
      stakable: false,
      withdrawable: false,
      GDpriceToStakedToken: 0,
    },
    {
      price: 0,
      ratio: 0,
      pool: 0,
      weight: 0,
      apr: 0,
      totalStaked: 0,
      vaultcap: 0,
      stakable: false,
      withdrawable: false,
      GDpriceToStakedToken: 0,
    },
    {
      price: 0,
      ratio: 0,
      pool: 0,
      weight: 0,
      apr: 0,
      totalStaked: 0,
      vaultcap: 0,
      stakable: false,
      withdrawable: false,
      GDpriceToStakedToken: 0,
    },
  ]);

  async function fetchData() {
    try {
      let calls = [];
      let calls2 = [
        { address: VAULT_ADDR, params: [0], name: "poolInfo" },
        { address: VAULT_ADDR, params: [1], name: "poolInfo" },
        { address: VAULT_ADDR, params: [2], name: "poolInfo" },
      ];

      const poolInfomation = await multicall(ValutABI, calls2);
      calls = [
        { address: PRICE_ADDR, params: [], name: "getGLPprice" },
        { address: PRICE_ADDR, params: [USDC_ADDR], name: "getPrice" },
        { address: PRICE_ADDR, params: [ETH_ADDR], name: "getPrice" },
        { address: PRICE_ADDR, params: [BTC_ADDR], name: "getPrice" },
      ];
      const _prices = await multicall(PriceABI, calls);
      let USDCSAmount = 0;
      let ETHSAmount = 0;
      let BTCSAmount = 0;

      if (_prices) {
        setGLPPrice(_prices[0][0] / Math.pow(10, 18));
        USDCSAmount =
          (poolInfomation[0].totalStaked * _prices[1][0]) / Math.pow(10, 48);
        ETHSAmount =
          (poolInfomation[1].totalStaked * _prices[2][0]) / Math.pow(10, 48);

        BTCSAmount =
          (poolInfomation[2].totalStaked * _prices[3][0]) / Math.pow(10, 48);
      }

      if (poolInfomation) {
        setPool([
          {
            price: _prices ? _prices[1][0] / Math.pow(10, 30) : 0,
            pool: USDCSAmount,
            weight: 0,
            apr:
              poolInfomation[0].APR / 100 > 15
                ? 17
                : poolInfomation[0].APR / 100 + 1,
            totalStaked: poolInfomation[0].totalStaked,
            withdrawable: poolInfomation[0].withdrawable,
            stakable: poolInfomation[0].stakable,
            vaultcap: poolInfomation[0].vaultcap,
          },
          {
            price: _prices ? _prices[2][0] / Math.pow(10, 30) : 0,
            pool: ETHSAmount,
            apr:
              poolInfomation[1].APR / 100 > 15
                ? 17
                : poolInfomation[1].APR / 100 + 1,
            totalStaked: poolInfomation[1].totalStaked,

            withdrawable: poolInfomation[1].withdrawable,
            stakable: poolInfomation[1].stakable,
            vaultcap: poolInfomation[1].vaultcap,
          },
          {
            price: _prices ? _prices[3][0] / Math.pow(10, 30) : 0,
            pool: BTCSAmount,

            apr:
              poolInfomation[2].APR / 100 > 15
                ? 17
                : poolInfomation[2].APR / 100 + 1,
            totalStaked: poolInfomation[2].totalStaked,

            withdrawable: poolInfomation[2].withdrawable,
            stakable: poolInfomation[2].stakable,
            vaultcap: poolInfomation[2].vaultcap,
          },
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getPoolImmutables() {
    const immutables = {
      factory: await poolContract.factory(),
      token0: await poolContract.token0(),
      token1: await poolContract.token1(),
      fee: await poolContract.fee(),
      tickSpacing: await poolContract.tickSpacing(),
      maxLiquidityPerTick: await poolContract.maxLiquidityPerTick(),
    };
    return immutables;
  }

  async function getPoolImmutables2() {
    const immutables = {
      factory: await poolContract2.factory(),
      token0: await poolContract2.token0(),
      token1: await poolContract2.token1(),
      fee: await poolContract2.fee(),
      tickSpacing: await poolContract2.tickSpacing(),
      maxLiquidityPerTick: await poolContract2.maxLiquidityPerTick(),
    };
    return immutables;
  }

  async function getPoolState() {
    const slot = await poolContract.slot0();
    const PoolState = {
      liquidity: await poolContract.liquidity(),

      sqrtPriceX96: slot[0],
      tick: slot[1],
      observationIndex: slot[2],
      observationCardinality: slot[3],
      observationCardinalityNext: slot[4],
      feeProtocol: slot[5],
      unlocked: slot[6],
    };
    return PoolState;
  }

  async function getPoolState2() {
    const slot = await poolContract2.slot0();
    const PoolState = {
      liquidity: await poolContract2.liquidity(),

      sqrtPriceX96: slot[0],
      tick: slot[1],
      observationIndex: slot[2],
      observationCardinality: slot[3],
      observationCardinalityNext: slot[4],
      feeProtocol: slot[5],
      unlocked: slot[6],
    };
    return PoolState;
  }

  async function fetchPrice() {
    const immutables = await getPoolImmutables();
    const state = await getPoolState();
    const DAI = new Token(42161, immutables.token0, 18, "GMD", "GMD Coin");
    const USDC = new Token(42161, immutables.token1, 6, "USDC", "USD Coin");
    console.log(immutables, state);

    //create a pool
    const DAI_USDC_POOL = new Pool(
      USDC,
      DAI,
      immutables.fee,
      state.sqrtPriceX96.toString(),
      state.liquidity.toString(),
      state.tick
    );
    const price1 = await DAI_USDC_POOL.token0Price;

    setPrice(Number(price1.toFixed(10)));

    const immutables2 = await getPoolImmutables2();
    const state2 = await getPoolState2();

    console.log(immutables2, state2);

    //create a pool
    const DAI_USDC_POOL2 = new Pool(
      USDC,
      DAI,
      immutables2.fee,
      state2.sqrtPriceX96.toString(),
      state2.liquidity.toString(),
      state2.tick
    );
    const price2 = await DAI_USDC_POOL2.token0Price;

    setPrice2(Number(price2.toFixed(10)));

    setMintedTokens(mintedTokens / Math.pow(10, 18));
    setLockTokens(lockedTokens / Math.pow(10, 18));
  }

  useEffect(() => {
    fetchData();

    fetchPrice();
    if (dataid) clearInterval(dataid);
    dataid = setInterval(() => {
      fetchPrice();
      fetchData();
    }, 20000);
  }, []);

  return (
    <TokenInfoContext.Provider
      value={{
        price,
        totalFees,
        pool,
        mintedTokens,
        lockedTokens,
        fetchData,
        gmusdPrice,
      }}
      children={children}
    />
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  getMulticallContract,
  getTokenContract,
  multicall,
} from "../utils/contracts";
import ValutABI from "../abis/ValutABI.json";
import ERC20ABI from "../abis/ERC20ABI.json";
import MINTABI from "../abis/MintABI.json";
import { xGND_ADDR, GND_ADDR, GMUSD_ADDR } from "../abis/address";
import { useAddress } from "./web3Context";
import { ethers } from "ethers";

const defaultVal = {
  GMUSDdata: {},
  allowance: false,

  fetchGMUSDdata: () => {},
};

export const GMUSDinfo = React.createContext(defaultVal);

export default function GMUSDInfo() {
  return React.useContext(GMUSDinfo);
}
let dataid = null,
  accountid = null;

export function GMUSDInfoProvider({ children }) {
  const account = useAddress();
  const [GMUSDdata, setGMUSDdata] = useState({
    totalSupply: 0,
    gdaiLocked: 0,
    gmdusdcLocked: 0,
  });

  const gdai = "0xd85E038593d7A098614721EaE955EC2022B9B91B";
  const gmdusdc = "0x3DB4B7DA67dd5aF61Cb9b3C70501B1BdB24b2C22";
  async function fetchGMUSDdata() {
    try {
      let calls = [
        {
          address: GMUSD_ADDR,
          name: "totalSupply",
          params: [],
        },
      ];
      let calls2 = [
        {
          address: gdai,
          name: "balanceOf",
          params: [GMUSD_ADDR],
        },
        {
          address: gmdusdc,
          name: "balanceOf",
          params: [GMUSD_ADDR],
        },
      ];

      const supply = await multicall(MINTABI, calls);
      const lockeddata = await multicall(MINTABI, calls2);
      setGMUSDdata({
        totalSupply: supply[0],
        gdaiLocked: lockeddata[0][0],
        gmdusdcLocked: lockeddata[1][0],
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchGMUSDdata();

    if (accountid) clearInterval(accountid);
    accountid = setInterval(() => {
      fetchGMUSDdata();
    }, 20000);
  }, [account]);

  return (
    <GMUSDinfo.Provider
      value={{
        GMUSDdata,

        fetchGMUSDdata,
      }}
      children={children}
    />
  );
}

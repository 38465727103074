import "../../styles/style.css";
import Header from "../../components/Home/Header";
import Features from "../../components/Home/Features";
import Tracking from "../../components/Home/Tracking";
import Topbar from "../../components/Topbar/Topbar";

function Home() {
  return (
    <div>
      <Header />
      <Features />
      {/* <Tracking/> */}
    </div>
  );
}

export default Home;

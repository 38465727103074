import "../../styles/style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

function Features() {
  return (
    <div>
      <section className="py-5 mb-5">
        <div className="container">
          <h2 className="section-title">Features</h2>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 mb-4 mb-lg-0">
              <h5>gmUSD</h5>
              <p className="text-dark">
                Algorithmic stablecoin fully backed by gDAI, gmdUSDC and
                Protocol Smart Arbitrage Leverage Mechanism (PSALM)
              </p>
              <p className="mb-5">
                <a
                  href="https://gmd-protocol.gitbook.io/gnd-protocol/gmusd-working-mechanism/what-is-gmusd"
                  target="blank"
                  className="text-primary mb-5"
                >
                  Find out More
                </a>
              </p>
              <h5>Liquidity Engine</h5>
              <p className="text-dark">
                Manages concentrated liquidity by incentivizing specific ranges
                and fees for pairs on Arbitrum.
              </p>
              <p className="mb-5">
                <a
                  href="https://gmd-protocol.gitbook.io/gnd-protocol/gmusd-working-mechanism/what-is-gmusd"
                  target="blank"
                  className="text-primary mb-5"
                >
                  Find out More
                </a>
              </p>
            </div>
            <div className="col-lg-4 mb-3 mb-lg-0">
              <h5>GND Farm</h5>
              <p className="text-dark">
                GND and xGND farms to rewards concentrated liquidity provider.
                Sustainable emissions and variable pool allocation(through
                voting)
              </p>
              <p className="mb-5">
                <a
                  href="https://gmd-protocol.gitbook.io/gnd-protocol/gmusd-working-mechanism/what-is-gmusd"
                  target="blank"
                  className="text-primary mb-5"
                >
                  Find out More
                </a>
              </p>
              <h5>Emissions-directing Voting</h5>
              <p className="text-dark">
                Revenue sharers of GND protocol(xGND stakers) can direct
                emissions to pairs through voting with their staked xGND.
              </p>
              <p className="mb-5">
                <a
                  href="https://gmd-protocol.gitbook.io/gnd-protocol/gmusd-working-mechanism/what-is-gmusd"
                  target="blank"
                  className="text-primary mb-5"
                >
                  Find out More
                </a>
              </p>
            </div>
            <div className="col-lg-4 text-center">
              <h6
                className="text-gray font-os font-weight-semibold mb-4"
                style={{ marginLeft: "0px" }}
              >
                Built on top of industry-leading protocols
              </h6>
              <Swiper
                slidesPerView={3}
                spaceBetween={0}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
                style={{
                  "--swiper-pagination-color": "#61b8b5",
                  "--swiper-pagination-bullet-inactive-color": "white",
                }}
                loop={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
              >
                <SwiperSlide>
                  <img src={require("../../images/bfr.png")}></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={require("../../images/magic.png")}></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={require("../../images/pendle.png")}></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={require("../../images/rdnt.png")}></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={require("../../images/vela.png")}></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={require("../../images/gdai.png")}></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={require("../../images/gmusd.png")}></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={require("../../images/gns.png")}></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={require("../../images/logoGMD.png")}></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img src={require("../../images/logoGMX.png")}></img>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Features;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { getMulticallContract, multicall } from "../utils/contracts";
import ValutABI from "../abis/ValutABI.json";
import ERC20ABI from "../abis/ERC20ABI.json";
import CASHBACKABI from "../abis/CASHBACKABI.json";
import OTCABI from "../abis/OTCABI.json";
import GMDStakingABI from "../abis/GMDStakingABI.json";
import {
  GMD_ADDR,
  esGMD_ADDR,
  GMD_STAKING_ADDR,
  GMD_STAKING_ADDR2,
  MINT_ADDR,
  USDC_ADDR,
  VAULT_ADDR,
  xGND_ADDR,
  GND_ADDR,
  CASHBACK_ADDR,
  OTC_ADDR,
  ZGMD_STAKING_ADDR,
  ZGMD_ADDR
} from "../abis/address";
import { useAddress } from "./web3Context";
import { ethers } from "ethers";

const defaultVal = {
  allowance2: 0,
  esGMDallowance2: 0,
  balance2: 0,
  esGMDbalance2: 0,
  totalStaked2: 0,
  totalesGMDStaked2: 0,
  withdrawable2: false,
  apy2: 0,
  apyEs2: 0,
  reward2: 0,
  rewardEs2: 0,
  reward22: 0,
  RPreward2: 0,
  RPrewardEs2: 0,
  stakedRP2: 0,
  stakedRPEs2: 0,
  stakedAmount2: 0,
  totalRevenue2: 0,
  poolPercent2: 0,
  poolPercentEs2: 0,
  totalRP2: 0,
  totalRPEs2: 0,
  boostPercent2: 0,
  boostPercentEs2: 0,
  stakedEsAmount2: 0,
  VestAmount2: 0,
  fetchStakingData2: () => {},
  fetchStakingAccountData2: () => {},
};

export const ZGMDStakingInfoContext = React.createContext(defaultVal);

export default function useZGMDStakingInfo() {
  return React.useContext(ZGMDStakingInfoContext);
}
let dataid = null,
  accountid = null;

export function ZGMDStakingInfoProvider({ children }) {
  const account = useAddress();
  const [allowance2, setAllowance2] = useState(false);
  const [OTCAllowance2, setOTCAllowance2] = useState(false);
  const [balance2, setBalance2] = useState(0);
  const [esGMDallowance2, setesGMDAllowance2] = useState(false);
  const [esGMDbalance2, setesGMDBalance2] = useState(0);
  const [totalStaked2, setTotalStaked2] = useState(0);
  const [totalesGMDStaked2, setTotalesGMDStaked2] = useState(0);
  const [withdrawable2, setWithdrawable2] = useState(false);
  const [apy2, setAPY2] = useState(0);
  const [VestAmount2, setVestAmount2] = useState(0);
  const [apyEs2, setAPYEs2] = useState(0);
  const [boostPercent2, setboostPercent2] = useState(0);
  const [boostPercentEs2, setboostPercentEs2] = useState(0);
  const [totalRP2, settotalRP2] = useState(0);
  const [totalRPEs2, settotalRPEs2] = useState(0);
  const [reward2, setReward2] = useState(0);
  const [rewardEs2, setRewardEs2] = useState(0);
  const [poolPercent2, setpoolPercent2] = useState(0);
  const [poolPercentEs2, setpoolPercentEs2] = useState(0);
  const [RPreward2, setRPReward2] = useState(0);
  const [stakedRP2, setstakedRP2] = useState(0);
  const [RPrewardEs2, setRPRewardEs2] = useState(0);
  const [stakedRPEs2, setstakedRPEs2] = useState(0);
  const [reward22, setReward22] = useState(0);
  const [stakedAmount2, setStakedAmount2] = useState(0);
  const [stakedEsAmount2, setEsStakedAmount2] = useState(0);
  const [totalRevenue2, settotalRevenue2] = useState(0);
  const [lockAllowance2, setLockAllowance2] = useState(0);
  const [CASHBACK, setCASHBACK] = useState(0);
  const [MaxCASHBACK, setMaxCASHBACK] = useState(0);
  const [xGNDCashBack, setxGNDCASHBACK] = useState(0);
  const [swapRate, setSwapRate] = useState(0);
  const [xGNDtotalBuyBack, setxGNDtotalBuyBack] = useState(0);
  const [BuyBackBalance2, setBuyBackBalance2] = useState(0);
  async function fetchStakingData2() {
    try {
      let calls = [
        {
          address: ZGMD_STAKING_ADDR,
          name: "poolInfo",
          params: [0],
        },
        {
          address: ZGMD_STAKING_ADDR,
          name: "withdrawable",
          params: [],
        },
        {
          address: ZGMD_STAKING_ADDR,
          name: "WETHPerSecond",
          params: [],
        },
        {
          address: ZGMD_STAKING_ADDR,
          name: "totalWETHdistributed",
          params: [],
        },
        {
          address: ZGMD_STAKING_ADDR,
          name: "totalAllocPoint",
          params: [],
        },
        {
          address: ZGMD_STAKING_ADDR,
          name: "totalWETHdistributed",
          params: [],
        },
      ];
      let result = await multicall(GMDStakingABI, calls);
      if (result) {
        const revenue1 =
          result[3][0] == 0 ? 0.05 : result[3][0] / Math.pow(10, 18);
        const revenue2 =
          result[5][0] == 0 ? 0.05 : result[5][0] / Math.pow(10, 18);
        const PoolPercent2 = (result[0].allocPoint * 100) / result[4];
        setAPY2(
          result[2] == 0
            ? (115 * Math.pow(10, 11) * PoolPercent2) / Math.pow(10, 18)
            : (result[2] * PoolPercent2) / Math.pow(10, 20)
        );
        console.log((result[2] * poolPercent2) / Math.pow(10, 20));
        settotalRevenue2(revenue1);
        setWithdrawable2(result[1][0]);
        settotalRP2(result[0].totalRP);
        setpoolPercent2(PoolPercent2);
      }
      let calls2 = [
        {
          address: ZGMD_STAKING_ADDR,
          name: "poolInfo",
          params: [1],
        },
        {
          address: ZGMD_STAKING_ADDR,
          name: "WETHPerSecond",
          params: [],
        },
        {
          address: ZGMD_STAKING_ADDR,
          name: "totalAllocPoint",
          params: [],
        },
      ];
      result = await multicall(GMDStakingABI, calls2);
      if (result) {
        const PoolPercent2 = (result[0].allocPoint * 100) / result[2];
        settotalRPEs2(result[0].totalRP);
        setpoolPercentEs2(PoolPercent2);
        setAPYEs2(
          result[1] == 0
            ? (115 * Math.pow(10, 11) * PoolPercent2) / Math.pow(10, 18)
            : (result[1] * PoolPercent2) / Math.pow(10, 20)
        );
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
    let calls2 = [
      {
        address: ZGMD_ADDR,
        name: "balanceOf",
        params: [ZGMD_STAKING_ADDR],
      },
      {
        address: ZGMD_ADDR,
        name: "balanceOf",
        params: [ZGMD_STAKING_ADDR],
      },
      {
        address: xGND_ADDR,
        name: "balanceOf",
        params: [CASHBACK_ADDR],
      },
      {
        address: GND_ADDR,
        name: "balanceOf",
        params: [OTC_ADDR],
      },
    ];
    let result2 = await multicall(ERC20ABI, calls2);
    setTotalStaked2(result2 ? result2[0][0] / Math.pow(10, 18) : 0);
    setTotalesGMDStaked2(result2 ? result2[1][0] / Math.pow(10, 18) : 0);
    setxGNDCASHBACK(result2 ? result2[2][0] / Math.pow(10, 18) : 0);
    setBuyBackBalance2(result2 ? result2[3][0] / Math.pow(10, 18) : 0);
    console.log(result2[2][0] / Math.pow(10, 18));

    calls2 = [
      {
        address: CASHBACK_ADDR,
        name: "viewCurrentCashbackRate",
        params: [],
      },
      {
        address: CASHBACK_ADDR,
        name: "maxCashbackRate",
        params: [],
      },
    ];

    result2 = await multicall(CASHBACKABI, calls2);
    console.log(result2[0][0] / 10);
    setCASHBACK(result2[0][0] / 10);
    setMaxCASHBACK(result2[1][0] / 10);

    calls2 = [
      {
        address: OTC_ADDR,
        name: "swapRate",
        params: [],
      },
      {
        address: OTC_ADDR,
        name: "totalXGND",
        params: [],
      },
    ];
    result2 = await multicall(OTCABI, calls2);
    console.log(result2[0][0] / 10);
    setSwapRate(result2[0][0] / 1);
    setxGNDtotalBuyBack(result2[1][0] / Math.pow(10, 18));
  }
  async function fetchStakingAccountData2() {
    try {
      let calls = [
        {
          address: xGND_ADDR,
          name: "allowance",
          params: [account, ZGMD_STAKING_ADDR],
        },
        {
          address: xGND_ADDR,
          name: "balanceOf",
          params: [account],
        },
        {
          address: xGND_ADDR,
          name: "allowance",
          params: [account, xGND_ADDR],
        },
        {
          address: GND_ADDR,
          name: "balanceOf",
          params: [account],
        },
        {
          address: GND_ADDR,
          name: "allowance",
          params: [account, CASHBACK_ADDR],
        },
        {
          address: xGND_ADDR,
          name: "allowance",
          params: [account, OTC_ADDR],
        },
      ];

      let result = await multicall(ERC20ABI, calls);
      if (result) {
        setAllowance2(parseInt(result[0][0]) >= parseInt(result[1][0]));
        setBalance2(result[1][0]);
        setesGMDAllowance2(parseInt(result[2][0]) >= parseInt(result[1][0]));
        setesGMDBalance2(result[3][0]);
        setLockAllowance2(parseInt(result[4][0]) >= parseInt(result[3][0]));
        setOTCAllowance2(parseInt(result[5][0]) >= parseInt(result[1][0]));
        console.log(parseInt(result[2][0]));
        console.log(parseInt(result[0][0]));
      }

      console.log(result);

      calls = [
        {
          address: ZGMD_STAKING_ADDR,
          name: "userInfo",
          params: [0, account],
        },
        {
          address: ZGMD_STAKING_ADDR,
          name: "pendingWETH",
          params: [0, account],
        },
        {
          address: ZGMD_STAKING_ADDR,
          name: "pendingWETH",
          params: [0, account],
        },
        {
          address: ZGMD_STAKING_ADDR,
          name: "pendingRP",
          params: [0, account],
        },
      ];

      result = await multicall(GMDStakingABI, calls);
      if (result) {
        const staked = result[0].amount;
        const rpStaked = result[0].RPamount;
        setReward2(result[1][0] / Math.pow(10, 18));
        setReward22(result[2][0] / Math.pow(10, 18));
        setStakedAmount2(staked);
        setstakedRP2(rpStaked);
        setRPReward2(result[3][0] / Math.pow(10, 18));
        setboostPercent2(
          (staked / Math.pow(10, 16) + rpStaked / Math.pow(10, 16)) /
            (staked / Math.pow(10, 18))
        );
        setVestAmount2(result[0].VestAmount);
      }
      console.log(result);

      calls = [
        {
          address: ZGMD_STAKING_ADDR,
          name: "userInfo",
          params: [1, account],
        },
        {
          address: ZGMD_STAKING_ADDR,
          name: "pendingWETH",
          params: [1, account],
        },
        {
          address: ZGMD_STAKING_ADDR,
          name: "pendingWETH",
          params: [1, account],
        },
        {
          address: ZGMD_STAKING_ADDR,
          name: "pendingRP",
          params: [1, account],
        },
      ];

      result = await multicall(GMDStakingABI, calls);
      if (result) {
        const staked = result[0].amount;
        const rpStaked = result[0].RPamount;
        setRewardEs2(result[1][0] / Math.pow(10, 18));
        setEsStakedAmount2(staked);
        setstakedRPEs2(rpStaked);
        setRPRewardEs2(result[3][0] / Math.pow(10, 18));
        setboostPercentEs2(
          (staked / Math.pow(10, 16) + rpStaked / Math.pow(10, 16)) /
            (staked / Math.pow(10, 18))
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchStakingData2();
    if (dataid) clearInterval(dataid);
    dataid = setInterval(() => {
      fetchStakingData2();
    }, 20000);
  }, []);

  useEffect(() => {
    if (!account) return;
    fetchStakingAccountData2();
    if (accountid) clearInterval(accountid);
    accountid = setInterval(() => {
      fetchStakingAccountData2();
    }, 20000);
  }, [account]);

  return (
    <ZGMDStakingInfoContext.Provider
      value={{
        allowance2,
        balance2,
        esGMDallowance2,
        esGMDbalance2,
        totalStaked2,
        totalesGMDStaked2,
        withdrawable2,
        apy2,
        apyEs2,
        poolPercent2,
        poolPercentEs2,
        reward2,
        rewardEs2,
        reward22,
        RPreward2,
        RPrewardEs2,
        totalRP2,
        totalRPEs2,
        stakedRP2,
        stakedRPEs2,
        boostPercent2,
        boostPercentEs2,
        stakedAmount2,
        stakedEsAmount2,
        totalRevenue2,
        fetchStakingData2,
        fetchStakingAccountData2,
        VestAmount2,
        lockAllowance2,
        CASHBACK,
        MaxCASHBACK,
        xGNDCashBack,
        swapRate,
        xGNDtotalBuyBack,
        OTCAllowance2,
        BuyBackBalance2,
      }}
      children={children}
    />
  );
}

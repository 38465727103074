/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import useTokenInfo from "../../hooks/useTokenInfo";
import { numberWithCommas } from "../../utils/functions";
import { UNIV3_LP_ADDR } from "../../abis/address";
import useGMDStakingInfo from "../../hooks/useGMDStakingInfo";
import GNDInfo from "../../hooks/GNDinfo";
import usePoolsInfo from "../../hooks/usePoolsInfo";
import { useState, useEffect } from "react";
import tokensInfo from "../../tokens/tokensInfo";
import pools from "../../tokens/Pools.json";
import { getUniv3Unclaimedfees } from "../../hooks/useTokenInfo";
import { ethers } from "ethers";

const Stats = ({ setNotification }) => {
  const md = useMediaQuery("(max-width : 900px)");
  const { GNDdata, buyback } = GNDInfo();
  const { lpPrices, tokenAmounts } = usePoolsInfo();
  const { price, pool, gmusdPrice } = useTokenInfo();
  const { totalRevenue, totalStaked } = useGMDStakingInfo();
  const [totalTVL, setTotalTVL] = useState(0);
  const [univ3fees, setUniv3Fees] = useState(0);
  const [psalm, setPSALM] = useState(0);

  // var TVL =
  //   GLPinVault2 * GLPPrice > 0 && (totalStaked + totalesGMDStaked) * price > 0
  //     ? (GLPinVault2 + glpWallet) * GLPPrice * Math.pow(10, 6) +
  //       (totalStaked + totalesGMDStaked) * price
  //     : 0;

  var TVL = 0;
  async function calcTotalTVL() {
    try {
      const response = await fetch("https://gnd-backend.vercel.app/getTvl");
      const data = await response.json();
      console.log(data)
      setTotalTVL(1000000);
    } catch (error) {
      console.error("Error fetching TVL:", error);
    }
  }

  useEffect(() => {
    calcTotalTVL();
    async function fetchData() {
      const fees = await getUniv3Unclaimedfees(UNIV3_LP_ADDR, 1.07);
      setUniv3Fees(fees+6000);
      const psalm = await getUniv3Unclaimedfees(
        "0xe3875996fc7a9315cb0d633b2a1af5b91d68b674",
        1.07
      );
      setPSALM(psalm);
    }
    fetchData();
  }, []);

  var overviews = [
    {
      text: "Total Value Locked",
      // value: <Box>${numberWithCommas(TVL.toFixed(2))}</Box>,
      value: (
        <Box>
          {"$"}
          {numberWithCommas(
            (parseInt(totalTVL) + totalStaked * price).toFixed(2)
          )}
        </Box>
      ),
    },
    {
      text: "Total Liquidity Managed",
      value: (
        <Box>
          {"$"}
          {numberWithCommas(totalTVL.toFixed(2))}
        </Box>
      ),
    },
    {
      text: "Total Revenue",
      value: (
        <Box>
          {/* {numberWithCommas((totalRevenue + buyback).toFixed(2))} ETH */}$
          {numberWithCommas(
            (
              (totalRevenue + buyback) * pool[1].price +
              univ3fees +
              psalm
            ).toFixed(2)
          )}
        </Box>
      ),
    },

    {
      text: "Total fees claimed",
      value: (
        <Box>
          {numberWithCommas((totalRevenue + buyback).toFixed(2))} ETH($
          {numberWithCommas(
            ((totalRevenue + buyback) * pool[1].price).toFixed(2)
          )}
          )
        </Box>
      ),
    },
  ];

  const assets = [
    {
      text: "Revenue claimed for dividends",
      value: (
        <Box>
          {numberWithCommas(totalRevenue.toFixed(2))} ETH ($
          {numberWithCommas((totalRevenue * pool[1].price).toFixed(2))})
        </Box>
      ),
    },
    {
      text: "Revenue claimed for buy back",
      value: (
        <Box>
          {numberWithCommas(buyback.toFixed(2))} ETH ($
          {numberWithCommas((pool[1].price * buyback).toFixed(2))})
        </Box>
      ),
    },
    {
      text: "Estimated unclaimed fees from Univ3 engine",
      value: <Box>${numberWithCommas(univ3fees.toFixed(2))}</Box>,
    },
    {
      text: "Estimated unclaimed fees from PSALM",
      value: <Box>${numberWithCommas(psalm.toFixed(2))}</Box>,
    },
  ];

  return (
    <>
      <Box display={"flex"} alignItems={"center"}>
        <Box fontSize={"34px"} mb={"8px"} fontWeight={"bold"}>
          Stats
        </Box>
        <ArbitranSVG ml={"10px"} />
      </Box>
      {/* <Box fontSize={"15px"} lineHeight={"21px"} color={"#b7b7bd"}>
        Arbitrum Total Stats start from 01 Sep 2021. <br />
        For detailed stats:{" "}
        <a
          href={""}
          target={"_blank"}
          style={{ textDecoration: "underline" }}
          rel="noreferrer"
        >
          https://stats.gmx.io.
        </a>
      </Box> */}
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        mt={"32px"}
        flexWrap={"wrap"}
      >
        <Panel>
          <PanelHeader>Overview</PanelHeader>
          <Divider />
          <PanelBody>
            {overviews.map((data, i) => {
              return (
                <Box
                  key={i}
                  display={"flex"}
                  justifyContent={"space-between"}
                  lineHeight={"130%"}
                >
                  <Box color={"rgba(255, 255, 255, 0.7)"}>{data.text}</Box>
                  <Box>{data.value}</Box>
                </Box>
              );
            })}
          </PanelBody>
        </Panel>
        <Panel mt={md ? "15px" : 0}>
          <PanelHeader>Revenue Dashboard</PanelHeader>
          <Divider />
          <PanelBody>
            {assets.map((data) => {
              console.log(univ3fees);
              return (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  lineHeight={"130%"}
                >
                  <Box color={"rgba(255, 255, 255, 0.7)"}>{data.text}</Box>
                  <Box>{data.value}</Box>
                </Box>
              );
            })}
          </PanelBody>
        </Panel>
      </Box>
    </>
  );
};

const Divider = styled(Box)`
  height: 0.5px;
  background: #61b8b5;
  margin: 10.5px -15px;
`;

const PanelHeader = styled(Box)`
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PanelBody = styled(Box)`
  > div {
    margin-bottom: 8px;
  }
  > div:last-child {
    margin: 0;
  }
`;

const Panel = styled(Box)`
  padding: 15px 15px 18px;
  border: 1px solid #61b8b5;
  border-radius: 4px;
  font-size: 15px;
  background: transparent;
  width: 100%;
  max-width: calc(50% - 8px);
  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
`;

const ArbitranSVG = styled(Box)`
  background: url("/icons/arbitran.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
`;

export default Stats;

import "../../styles/style.css";
import CloseIcon from "@material-ui/icons/Close";
import { React, useState, useEffect } from "react";
import tokensInfo from "../../tokens/tokensInfo";
import pools from "../../tokens/Pools.json";

function TokenModal({ exitModal, handleTokenSelect }) {
  const [search, setSearch] = useState("");

  const handleTokenClick = (token0, token1, index) => {
    handleTokenSelect(token0, token1, index);
    exitModal();
  };

  const handleSearch = (text) => {
    setSearch(text);
  };


  return (
    <div>
      <div
        className="container"
        style={{
          border: "1px solid #61b8b5",
          borderRadius: "4px",
          width: "400px",
          height: "500px",
          backgroundColor: "black",
          color: "white",
          overflow: "scroll",
        }}
      >
        <div className="row d-flex justify-content-center mt-3">
          <div
            className="col-12 align-items-center text-center"
            style={{ fontSize: "20px" }}
          >
            Select Tokens
            <CloseIcon
              className="exit"
              onClick={() => {
                exitModal();
              }}
            />
          </div>
        </div>
        <div className="row d-flex justify-content-center mt-2">
          <div className="col-12 align-items-center">
            <input
              style={{
                width: "100%",
                backgroundColor: "black",
                color: "white",
                border: "1px solid #c0c0c0",
                borderRadius: "4px",
              }}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search"
            ></input>
          </div>
        </div>

        {pools.toReversed()
          // .filter(
          //   (item) => item.name.toLowerCase().search(search.toLowerCase()) != -1
          // )
          .map((item, index) => {
            if(item.name.toLowerCase().search(search.toLowerCase()) == -1){
              return (
                <div></div>
              );
            }
            
            let pair = item.name.split("-");
            let token0 = tokensInfo[pair[0]];
            let token1 = tokensInfo[pair[1]];
            if (token0.short === "WETH" && token1.short === "WETH") {
              return null; // hide the component
            }
            return (
              <div className="row d-flex">
                <div className="col-7">
                  <div
                    className="row d-flex align-items-center mt-2 mb-1"
                    style={{
                      borderRadius: "4px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleTokenClick(token0.short, token1.short, pools.length - 1 - index)
                    }
                  >
                    <div className="col-1 justify-content-center ml-2">
                      <img
                        className="logo small"
                        style={{ display: "block", margin: "-10px" }}
                        src={token0.img}
                      />
                    </div>
                    <div className="col-1 justify-content-center">
                      <img
                        className="logo small"
                        style={{ display: "block", margin: "-10px" }}
                        src={token1.img}
                      />
                    </div>
                    <div className="col-3 text-left ml-2">
                      {token0.short}/{token1.short} { index > 4 ? <span style={{ color: "red" }}>(Legacy)</span> : <span></span> }
                    </div>
                  </div>
                </div>
                <div className="col-5">
                  <div
                    className="row d-flex align-items-center mt-2 ml-1 mb-1"
                    style={{
                      borderRadius: "4px",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleTokenClick(token0.short, token1.short, pools.length - 1 - index)
                    }
                  >
                    <div className="col-12">
                      <div className="row d-flex">
                        <div
                          className="col-12 text-start"
                          style={{ fontSize: "10px" }}
                        >
                          <span style={{ color: "#61b8b5" }}>Fees:</span>{" "}
                          {item["fees"]}%
                        </div>
                      </div>
                      <div className="row d-flex">
                        <div
                          className="col-12 text-start"
                          style={{ fontSize: "10px" }}
                        >
                          <span style={{ color: "#61b8b5" }}>MIN:&nbsp;</span>{" "}
                          {item["lowBound"]}
                        </div>
                      </div>
                      <div className="row d-flex">
                        <div
                          className="col-12 text-start"
                          style={{ fontSize: "10px" }}
                        >
                          <span style={{ color: "#61b8b5" }}>MAX:</span>{" "}
                          {item["highBound"]}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr
                  style={{
                    border: "1px dashed #61b8b5",
                    width: "350px",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                ></hr>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default TokenModal;

import Topbar from "../Topbar/Topbar";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useTokenInfo from "../../hooks/useTokenInfo";
import useGMDStakingInfo from "../../hooks/useGMDStakingInfo";
import GNDInfo from "../../hooks/GNDinfo";
import usePoolsInfo from "../../hooks/usePoolsInfo";
import { numberWithCommas } from "../../utils/functions";
import CountUp from "react-countup";
import { useState, useEffect } from "react";
import tokensInfo from "../../tokens/tokensInfo";
import pools from "../../tokens/Pools.json";
import { ethers } from "ethers";
// import db from '../../database/index'

function Header() {
  // const updateTvl = async (data) => {
  //   const res = await fetch("https://gnd-backend.vercel.app/updateTvl", {
  //     method: "POST",
  //     headers: {
  //       "Content-type": "application/json",
  //     },
  //     credentials: "include",
  //     body: JSON.stringify(data),
  //   });
  // };

  const navigate = useNavigate();
  const handleRouteDashboard = useCallback(
    () => navigate("/dashboard", { replace: true }),
    [navigate]
  );
  const { lpPrices, tokenAmounts, price, fetchPrices } = usePoolsInfo();
  const { totalStaked } = useGMDStakingInfo();
  const [totalTVL, setTotalTVL] = useState(0);
  const [fetchedTVL, setFetchedTVL] = useState(0);

  async function calcTotalTVL() {
    let _totalTVL = 0;
    for (let i = 0; i < pools.length; i++) {
      if (lpPrices[i][0] == 0 || lpPrices[i][1] == 0) {
        return;
      }
      if (i == 6 || i == 16) {
        _totalTVL +=
          (lpPrices[i][0] * tokenAmounts[i][0]) / Math.pow(10, 6) +
          (lpPrices[i][1] * tokenAmounts[i][1]) / Math.pow(10, 6);
      } else if (i == 7) {
        _totalTVL +=
          (lpPrices[i][0] * tokenAmounts[i][0]) / Math.pow(10, 8) +
          (lpPrices[i][1] * tokenAmounts[i][1]) / Math.pow(10, 18);
      } else if (i == 15) {
        _totalTVL +=
          (lpPrices[i][0] * tokenAmounts[i][0]) / Math.pow(10, 18) +
          (lpPrices[i][1] * tokenAmounts[i][1]) / Math.pow(10, 6);
      } else {
        _totalTVL +=
          lpPrices[i][0] * ethers.utils.formatEther(tokenAmounts[i][0]) +
          lpPrices[i][1] * ethers.utils.formatEther(tokenAmounts[i][1]);
      }
    }
    setTotalTVL(_totalTVL);
  }

  // if (totalTVL >= 12000000) {
  //   // update database
  //   const data = {
  //     tvl: totalTVL,
  //   };
  //   updateTvl(data);
  // }

  async function getTotalTVL() {
    try {
      const response = await fetch("https://gnd-backend.vercel.app/getTvl", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      const data = await response.json();
      console.log(data.tvl);
      setFetchedTVL(data.tvl);
      console.log("FETCHED TVL");
    } catch (error) {
      console.error("Error fetching TVL:", error);
    }
  }

  // getTotalTVL();
  useEffect(() => {
    getTotalTVL();
    const intervalId = setInterval(() => {
      getTotalTVL();
    }, 20000); // 20 seconds in milliseconds

    // Cleanup function to clear interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <header
        className="foi-header landing-header"
        style={{
          backgroundImage:
            "url(" +
            require("../../images/Bg_2@2x.png") +
            ")" +
            ", url(" +
            require("../../images/Bg_2@2x.png") +
            ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "167px, 65%",
          backgroundPosition: "right top",
          paddingTop: "0px",
        }}
      >
        <Topbar color="black" />
        <div className="container">
          <div className="header-content">
            <div className="row d-flex">
              <div className="col-6" style={{ minWidth: "400px" }}>
                <h2>Protocol-Arbitrage Stablecoin</h2>
                <p className="text-dark">
                  gmUSD built on top of GMD Protocol and Gains Network bringing
                  additional yields on top of underlying vaults(gmdUSDC and
                  gDAI)
                </p>
                <h2>Concentrated Liquidity Engine & Farm</h2>
                <p className="text-dark">
                  Incentivize capital efficency on Arbitrum Ecosystem. Provide
                  concentrated liquidity on your favorite assets to earn yield.
                </p>
                <button
                  className="btn btn-primary mb-4"
                  onClick={handleRouteDashboard}
                >
                  Enter App
                </button>
                <a
                  href="https://gmd-protocol.gitbook.io/gnd-protocol/gmusd-working-mechanism/what-is-gmusd"
                  target="blank"
                >
                  <button className="btn btn-primary mb-4 ml-2">Docs</button>
                </a>

                <div class="col-12" style={{ marginLeft: "-15px" }}>
                  <div class="media landing-feature">
                    <div class="media-body">
                      <h5>
                        Total Value Locked{" "}
                        <span
                          class="landing-feature-icon"
                          style={{ marginLeft: "5px" }}
                        ></span>
                      </h5>

                      <p style={{ fontSize: "30px", color: "#61b8b5" }}>
                        {parseInt(fetchedTVL) > 0 ? (
                          <div>
                            {"$"}
                            {numberWithCommas(
                              (
                                parseInt(fetchedTVL) +
                                totalStaked * price
                              ).toFixed(2)
                            )}
                          </div>
                        ) : (
                          <div>
                            <p style={{ fontSize: "30px", color: "#61b8b5" }}>
                              Calculating...
                            </p>
                          </div>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 justify-content-center">
                <img
                  src={require("../../images/app_1.png")}
                  alt="app"
                  style={{ minWidth: "388px" }}
                  // className="mx-auto d-block"
                  // style={{filter: "drop-shadow(0 0 0.75rem rgb(255, 217, 0))"}}
                  // style={{boxShadow: "0px 20px 20px rgba(255,34,34,1)"}}
                  // style={{background: "linear-gradient(180deg, transparent 80%,  rgba(255,0,0,0.5)"}}
                  // style={{ minWidth: "388px" }}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import styled from "styled-components";
import Stats from "./Stats";
import Tokens from "./Tokens";
import GmUSD from "./GmUSD";
import Topbar from "../../components/Topbar/Topbar";

const Dashboard = ({ setNotification }) => {
  return (
    <div>
      <Topbar color="white" style={{ paddingTop: "24px" }} />
      <StyledContainer>
        <div className="container-fluid">
          <Stats />
          <Box display={"flex"} alignItems={"center"} mt={"40px"}>
            <Box fontSize={"34px"} mb={"8px"} fontWeight={"bold"}>
              Tokens
            </Box>
            <ArbitranSVG ml={"10px"} />
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <Box fontSize={"15px"} lineHeight={"21px"} color={"#b7b7bd"}>
              GND and gmdUSD data
            </Box>
          </Box>
          <StyledContainer>
            <div className="row d-flex justify-content-center">
              <div className="col-6" style={{ minWidth: "500px" }}>
                <Tokens />
              </div>
              <div className="col-6" style={{ minWidth: "500px" }}>
                <GmUSD />
              </div>
            </div>
          </StyledContainer>
        </div>
      </StyledContainer>
    </div>
  );
};

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-top: 46px;
  max-width: 1264px;
  padding-left: 32px;
  padding-right: 32px;
  margin: 0 auto;
  letter-spacing: 0.5px;
  @media screen and (max-width: 600px) {
    padding-left: 22px;
    padding-right: 22px;
  }
`;
const ArbitranSVG = styled(Box)`
  background: url("/icons/arbitran.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
`;
export default Dashboard;

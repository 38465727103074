/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  getMulticallContract,
  getTokenContract,
  multicall,
} from "../utils/contracts";
import ValutABI from "../abis/ValutABI.json";
import ERC20ABI from "../abis/ERC20ABI.json";
import MINTABI from "../abis/MintABI.json";
import {
  BTC_ADDR,
  ETH_ADDR,
  GDlptoken,
  xGND_ADDR,
  USDC_ADDR,
  VAULT_ADDR,
} from "../abis/address";
import { useAddress } from "./web3Context";
import { ethers } from "ethers";

const defaultVal = {
  mintAccountData: {},
  allowance: false,

  fetchMintAccountData: () => {},
};

export const VestContext = React.createContext(defaultVal);

export default function VestInfo() {
  return React.useContext(VestContext);
}
let dataid = null,
  accountid = null;

export function VestInfoProvider({ children }) {
  const account = useAddress();
  const [mintData, setMintData] = useState({
    totalSupply: 0,
  });
  const [mintAccountData, setMintAccountData] = useState({
    VestPositions: 0,
    positions: new Array(),
  });
  const [allowance, setAllowance] = useState(false);

  function getTimestampInSeconds() {
    console.log(Math.floor(Date.now() / 1000));
    return Math.floor(Date.now() / 1000);
  }

  async function fetchMintAccountData() {
    try {
      let calls = [
        {
          address: xGND_ADDR,
          name: "userPositions",
          params: [account],
        },
      ];

      const positions = await multicall(MINTABI, calls);
      const pos = parseInt(positions[0], 10);
      console.log(pos);
      let vestlist = new Array();
      for (let i = 0; i < pos; i++) {
        calls = [
          {
            address: xGND_ADDR,
            name: "userInfo",
            params: [account, i],
          },
        ];
        let result = await multicall(MINTABI, calls);

        vestlist.push([
          parseInt(result[0].totalVested, 10),
          parseInt(result[0].lastInteractionTime, 10),
          parseInt(result[0].VestPeriod, 10),
        ]);
      }
      console.log(vestlist);
      setMintAccountData({
        VestPositions: pos,
        positions: vestlist,
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (!account) return;
    fetchMintAccountData();
    getTimestampInSeconds();
    if (accountid) clearInterval(accountid);
    accountid = setInterval(() => {
      fetchMintAccountData();
      getTimestampInSeconds();
    }, 20000);
  }, [account]);

  return (
    <VestContext.Provider
      value={{
        mintAccountData,
        allowance,
        fetchMintAccountData,

        getTimestampInSeconds,
      }}
      children={children}
    />
  );
}

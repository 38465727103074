export const MULTICALL_ADDR = "0x842eC2c7D803033Edf55E478F461FC547Bc54EB2";
export const VAULT_ADDR = "0xA7Ce4434A29549864a46fcE8662fD671c06BA49a";
export const NEWVAULT_ADDR = "0x8080B5cE6dfb49a6B86370d6982B3e2A86FBBb08";
export const PRICE_ADDR = "0x06e31Ad70174D7dB1Eb06fCDa75Aa254E311BC3f";
export const ETH_ADDR = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
export const BTC_ADDR = "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f";
export const USDC_ADDR = "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8";
export const GMUSD_ADDR = "0xEC13336bbd50790a00CDc0fEddF11287eaF92529";
export const GDlptoken = [
  "0x4A723DE8aF2be96292dA3F824a96bfA053d4aF66",
  "0xc5182E92bf001baE7049c4496caD96662Db1A186",
  "0xEffaE8eB4cA7db99e954adc060B736Db78928467",
];
export const GD2lptoken = [
  "0x3DB4B7DA67dd5aF61Cb9b3C70501B1BdB24b2C22",
  "0x1E95A37Be8A17328fbf4b25b9ce3cE81e271BeB3",
  "0x147FF11D9B9Ae284c271B2fAaE7068f4CA9BB619",
];
export const xGND_ADDR = "0x40EA7f6D6964413D4a26A0a268542DAE9F55768E";
export const GMD_STAKING_ADDR2 = "0x535ec56479892d9C02fe2Bb86CeBF7ed62E81131";
export const GMD_STAKING_ADDR = GMD_STAKING_ADDR2;
export const ZGMD_STAKING_ADDR = "0xF373f08c99Aa93E845585F6fDFe2c45DBdb600DB";

export const GMD_ADDR = xGND_ADDR;
export const GND_ADDR = "0xd67a097dce9d4474737e6871684ae3c05460f571";
export const ZGMD_ADDR = "0x40b96DAC9386E252E86eaCf330C138561b9dE37c";
export const esGMD_ADDR = GND_ADDR;
export const UNIV3_ADDR = "0x4256a846F6135823d21121A5F97Aa2dcf6AF1DC6";
export const UNIV3_LP_ADDR = "0xd8769d8826149B137AF488b1e9Ac0e3AFdbC058a";
export const BUYBACK_ADDR = "0x5F0feEF4dAFEa7fb4d6Ca89C047767885226b5f9";
export const CASHBACK_ADDR = "0x587A0F59FcB1a7d74dD5655c63C45a1DdD36ECb3";
export const SWAPZGND_ADDR = "0x032c7b7De95eFa3787C300495e95E0645BCbd6f6";
export const OTC_ADDR = "0x29cF62536c1019A8aDaC35A61A7F12b82a6C12ae";
export const MAX =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";

/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import styled from "styled-components";
import Button from "../../components/Button";
import ZGNDmodal from "../../components/ZGNDmodal";
import GMDStakingModal2 from "../../components/esGMDStakingModal";
import LockModal from "../../components/lockModal";
import { useState } from "react";
import useTokenInfo from "../../hooks/useTokenInfo";
import VestInfo from "../../hooks/VestContext";
import { numberWithCommas, figureError } from "../../utils/functions";
import { useAddress, useWeb3Context } from "../../hooks/web3Context";
import useGMDStakingInfo from "../../hooks/useGMDStakingInfo";
import {
  getGMDXGNDContract,
  getXGNDContract,
  getZGNDContract,
  getCashBackContract,
  getOTCContract,
  getSwapZGNDContract,
} from "../../utils/contracts";
import { ethers } from "ethers";
import Topbar from "../../components/Topbar/Topbar";
import TopbarDapp from "../../components/Topbar2/Topbar";
import CountDown from "../../components/Countdown";
import { SWAPZGND_ADDR } from "../../abis/address";
const ZGND = ({ setNotification }) => {
  const { price, pool } = useTokenInfo();
  const { mintAccountData, getTimestampInSeconds } = VestInfo();
  const PosNumbers = mintAccountData.VestPositions;
  const Positions = mintAccountData.positions;
  const {
    allowance,
    esGMDallowance,
    esGMDbalance,
    balance,
    totalStaked,
    totalesGMDStaked,

    totalRP,
    totalRPEs,
    ZGNDbalance,
    fetchStakingAccountData,

  } = useGMDStakingInfo();

  const account = useAddress();
  const { connect, provider } = useWeb3Context();

  function onConnect() {
    connect().then((msg) => {
      if (msg.type === "error") {
        setNotification(msg);
      }
    });
  }

  const [open, setOpen] = useState(false);
  const [type, setType] = useState(1);
  const [open2, setOpen2] = useState(false);
  const [type2, setType2] = useState(1);
  const [open3, setOpen3] = useState(false);
  const [type3, setType3] = useState(1);

  const [amount, setAmount] = useState(0);
  const [maxPressed, setMaxPresssed] = useState(false);
  const [pending, setPending] = useState(false);
  const [amount2, setAmount2] = useState(0);
  const [maxPressed2, setMaxPresssed2] = useState(false);
  const [pending2, setPending2] = useState(false);
  const [amount3, setAmount3] = useState(0);
  const [maxPressed3, setMaxPresssed3] = useState(false);
  const [pending3, setPending3] = useState(false);

  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const getBalance = (amount, decimals = 18) => {
    return numberWithCommas((amount / Math.pow(10, decimals)).toFixed(5));
  };
  const getBalanceUSD = (amount, price, decimals = 18) => {
    return numberWithCommas(
      ((price * amount) / Math.pow(10, decimals)).toFixed(5)
    );
  };
  const getStakedUSD = price * (totalStaked + totalRP);
  const getStakedUSDEs = price * (totalesGMDStaked + totalRPEs);
  const onApprove = async (i) => {
    setPending(true);
    try {
      const tokenContract = getZGNDContract(provider.getSigner());
      const estimateGas = await tokenContract.estimateGas.approve(
        SWAPZGND_ADDR,
        "115792089237316195423570985008687907853269984665640564039457584007913129639935"
      );
      console.log(estimateGas.toString());

      const tx = {
        gasLimit: estimateGas.toString(),
      };
      const approveTx = await tokenContract.approve(
        SWAPZGND_ADDR,
        "115792089237316195423570985008687907853269984665640564039457584007913129639935",
        tx
      );
      await approveTx.wait();
      fetchStakingAccountData();
    } catch (error) {
      console.log(error);
      figureError(error, setNotification);
    }
    setPending(false);
  };

  const onConfirm = async () => {
    setPending(true);
    try {
      const ZGNDContract = getZGNDContract(provider.getSigner());
      let estimateGas, tx;

      estimateGas = await ZGNDContract.estimateGas.mint(
          maxPressed ? esGMDbalance : ethers.utils.parseEther(amount)
      );

      console.log(estimateGas.toString());

      const ttx = {
        gasLimit: Math.ceil(estimateGas.toString() * 1.2),
      };

      tx = await ZGNDContract.mint(
          maxPressed ? esGMDbalance : ethers.utils.parseEther(amount),
          ttx
      );

      await tx.wait();
      fetchStakingAccountData();
    } catch (error) {
      console.log(error);
      figureError(error, setNotification);
    }
    setPending(false);
  };

  const onSWAP = async () => {
    setPending(true);
    try {
      const ZGNDContract = getSwapZGNDContract(provider.getSigner());
      
      let estimateGas, tx;

      estimateGas = await ZGNDContract.estimateGas.swapTokens(
        
      );

      console.log(estimateGas.toString());

      const ttx = {
        gasLimit: Math.ceil(estimateGas.toString() * 1.2),
      };

      tx = await ZGNDContract.swapTokens(

      );

      await tx.wait();
      fetchStakingAccountData();
    } catch (error) {
      console.log(error);
      figureError(error, setNotification);
    }
    setPending(false);
  };


  return (
    <div>
      <TopbarDapp
        color="white"
        connectEnabled={true}
        style={{ paddingTop: "24px" }}
      />
      <StyledContainer>
        <ZGNDmodal
          open={open}
          setOpen={setOpen}
          maxPressed={maxPressed}
          setMaxPressed={setMaxPresssed}
          onConfirm={onConfirm}
          pending={pending}
          setPending={setPending}
          setNotification={setNotification}
          max={esGMDbalance / Math.pow(10, 18)}
          amount={amount}
          setAmount={setAmount}
          type={type}
        />
        <Box fontSize={"34px"} mb={"8px"} fontWeight={"bold"}>
          zGND is now concluded, please retrieve for xGND
        </Box>

        <Button
          type={"secondary"}
          width={"120px"}
          height={"30px"}
          onClick={toggleDetails}
          mb={"10px"}
        >
          {showDetails ? "Hide" : "Read more"}
        </Button>
        {showDetails && (
          <>
            <Box fontSize={"18px"} fontWeight={"200"} mt={"1px"}>
              1. Convert GND to zGND 1:1
            </Box>
            <Box fontSize={"18px"} fontWeight={"200"} mt={"10px"}>
              2. zGND is the liquid version of xGND. You can exit your zGND position via uniswap
            </Box>
            <Box fontSize={"18px"} fontWeight={"200"} mt={"10px"}>
              3. zGND earns a share of the ETH rewards generated by xGND and RPs and are eligible for launchpad.
            </Box>
          </>
        )}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          marginTop={"20px"}
        >
          <Panel>
            <PanelHeader justifyContent={"center"}>
           
              <CoinSVG
                  justifyContent={"center"}
                  style={{
                    background: `url(/icons/57.png)`,
                    backgroundSize: "100% 100%",
                    width: "80px",
                    height: "80px",
                  }}
                />
              
            </PanelHeader>

            <Divider />
            <PanelBody>
              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Conversion rate</Box>
                <Box>1:1.2</Box>
              </Box>
              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Price</Box>
                <Box>${price.toFixed(2)}</Box>
              </Box>
              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>zGND balance</Box>
                <Box>
                  {getBalance(ZGNDbalance)} zGND (${getBalanceUSD(ZGNDbalance, price)})
                </Box>
              </Box>

              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>GND balance</Box>
                <Box>
                  {getBalance(esGMDbalance)} GND (${getBalanceUSD(esGMDbalance, price)})
                </Box>
              </Box>
             
        
            </PanelBody>
            <Divider />

            <Box display={"flex"} justifyContent={"center"}>
              {!account ? (
                <Button
                  type={"primary"}
                  width={"136px"}
                  height={"36px"}
                  onClick={() => onConnect()}
                >
                  Connect Wallet
                </Button>
              ) : (
                <>
                  <Button
                    type={"primary"}
                    width={"285px"}
                    height={"46px"}
                    disabled={pending}
                    onClick={() => {
                      onApprove();
                      onSWAP();
                    }}
                  >
                    {"Retrieve your zGND balance for xGND (1:1.2)"}
                  </Button>
                </>
              )}
            </Box>
          </Panel>
         
        </Box>
      </StyledContainer>
    </div>
  );
};

const CoinSVG = styled(Box)`
  width: 40px;
  height: 40px;
`;

const Panel = styled(Box)`
  padding: 15px 15px 18px;
  border: 1px solid #61b8b5;
  border-radius: 4px;
  font-size: 15px;
  background: transparent;
  width: 100%;

  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
  margin-bottom: 15px;
`;
const PanelHeader = styled(Box)`
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
`;

const PanelBody = styled(Box)`
  > div {
    display: flex;
    justify-content: space-between;
    line-height: 130%;
    margin-top: 8px;
  }
  > div:first-child {
    margin: 0;
  }
`;

const Divider = styled(Box)`
  height: 1px;
  background: #61b8b5;
  margin: 10.5px -15px;
`;

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  max-width: 600px;
  padding-left: 32px;
  padding-right: 32px;
  margin: 0 auto;
  letter-spacing: 0.5px;
  padding-bottom: 100px;
  @media screen and (max-width: 600px) {
    padding-left: 22px;
    padding-right: 22px;
  }
`;



export default ZGND;

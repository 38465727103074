/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import styled from "styled-components";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import hexToRgba from "hex-to-rgba";
import { useState } from "react";
import VestInfo from "../../hooks/VestContext";
import { numberWithCommas } from "../../utils/functions";
import useTokenInfo from "../../hooks/useTokenInfo";
import GMUSDInfo from "../../hooks/gmUSDinfo";
import useGMDStakingInfo from "../../hooks/useGMDStakingInfo";
import Button from "../../components/Button";

const GmUSD = () => {
  const { mintData } = VestInfo();
  const {
    gmusdPrice,

    mintedTokens,
    lockedTokens,
  } = useTokenInfo();
  const { totalStaked } = useGMDStakingInfo();
  const { GMUSDdata } = GMUSDInfo();
  const [gmxActiveIndex, setGMXActiveIndex] = useState(null);

  const onGMXDistributionChartEnter = (_, index) => {
    setGMXActiveIndex(index);
  };

  const onGMXDistributionChartLeave = (_, index) => {
    setGMXActiveIndex(null);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="stats-label">
          <div
            className="stats-label-color"
            style={{ backgroundColor: payload[0].color }}
          ></div>
          {payload[0].value.toFixed(2)}% {payload[0].name}
        </div>
      );
    }
  };

  const GMD = [
    { text: "Price", value: `$${gmusdPrice.toFixed(2)}` },

    {
      text: "gmdUSDC locked",
      value: `${numberWithCommas(
        (GMUSDdata.gmdusdcLocked / Math.pow(10, 18)).toFixed(2)
      )} gmdUSDC`,
    },

    {
      text: "gDAI locked",
      value: `${numberWithCommas(
        (GMUSDdata.gdaiLocked / Math.pow(10, 18)).toFixed(2)
      )} gDAI`,
    },

    {
      text: "Total Supply",
      value: `${numberWithCommas(
        (GMUSDdata.totalSupply / Math.pow(10, 18)).toFixed(2)
      )} gmUSD`,
    },

    {
      text: "Natural Yield",
      value: `15-25% APY`,
    },

    {
      text: "Circulating Market Cap",
      value: `$${numberWithCommas(
        (gmusdPrice * (GMUSDdata.totalSupply / Math.pow(10, 18))).toFixed(2)
      )}`,
    },
    {
      text: "Fully Diluted Market Cap",
      value: `$${numberWithCommas(
        ((gmusdPrice * GMUSDdata.totalSupply) / Math.pow(10, 18)).toFixed(2)
      )}`,
    },
  ];

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        mt={"24px"}
        flexWrap={"wrap"}
      >
        <Panel>
          <Box maxWidth={"calc(100%)"} width={"100%"}>
            <PanelHeader>
              <GMXSVG mr={"8px"} />
              <Box>
                <Box lineHeight={"130%"}>gmUSD</Box>
                <Box fontSize={"12px"} color={"#a9a9b0"} lineHeight={"130%"}>
                  gmUSD
                </Box>
              </Box>
            </PanelHeader>
            <Divider />

            <PanelBody>
              {GMD.map((data) => {
                return (
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    lineHeight={"19px"}
                  >
                    <Box color={"rgba(255, 255, 255, 0.7)"}>{data.text}</Box>
                    <Box>{data.value}</Box>
                  </Box>
                );
              })}
            </PanelBody>
          </Box>
          {/* <Box margin={"-10px -10px -10px 15px"}>

          </Box> */}
        </Panel>
      </Box>
      <Box
        display={"flex"}
        alignContent={"center"}
        mb={"20px"}
        mt={"20px"}
        justifyContent={"center"}
      >
        <a
          href="https://app.uniswap.org/#/swap?outputCurrency=0xEC13336bbd50790a00CDc0fEddF11287eaF92529"
          target="blank"
        >
          <Button
            type={"secondary"}
            style={{ marginRight: "15px" }}
            width={"125px"}
            height={"36px"}
          >
            Buy gmUSD
          </Button>
        </a>
        <a
          href={
            "https://dexscreener.com/arbitrum/0x416edcdb7cf15f1f2894105b027211bd213db539"
          }
          target={"_blank"}
        >
          <Button type={"secondary"} width={"105px"} height={"36px"}>
            Chart
          </Button>
        </a>
      </Box>
    </Box>
  );
};

const Divider = styled(Box)`
  height: 1px;
  background: #61b8b5;
  margin: 10.5px -15px;
`;

const PanelHeader = styled(Box)`
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
`;

const PanelBody = styled(Box)`
  > div {
    margin-bottom: 8px;
  }
  > div:last-child {
    margin-bottom: 16px;
  }
`;

const Panel = styled(Box)`
  padding: 15px 15px 18px;
  border: 1px solid #61b8b5;
  border-radius: 4px;
  font-size: 15px;
  background: transparent;
  width: 100%;
  max-width: calc(100%);
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    /* max-width: 500px; */
  }

  @media screen and (max-width: 1100px) {
    max-width: 100%;
  }
  @media screen and (max-width: 660px) {
    flex-direction: column;
    > div:nth-child(1) {
      max-width: 100%;
    }
  }
`;

const GMXSVG = styled(Box)`
  background: url("/icons/gmusdlogo.png");
  background-size: 100% 100%;
  width: 48px;
  height: 45px;
`;

export default GmUSD;

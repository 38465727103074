import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import { useAddress, useWeb3Context } from "../../hooks/web3Context";
import Hamburger from "../Topbar/Hamburger";
import { Box, useMediaQuery } from "@mui/material";

function Topbar({
  activePage,
  setActivePage,
  setNotification,
  color,
  connectEnabled,
}) {
  const { connect, disconnect } = useWeb3Context();
  function onConnect() {
    connect().then((msg) => {
      if (msg.type === "error") {
        setNotification(msg);
      }
    });
  }
  const account = useAddress();

  const ellipsis = account
    ? account.slice(0, 6) +
      "..." +
      account.substring(account.length - 5, account.length)
    : "Connect Wallet";

  const navigate = useNavigate();
  const handleRouteHome = useCallback(
    () => navigate("/", { replace: true }),
    [navigate]
  );
  const handleRouteDashboard = useCallback(
    () => navigate("/dashboard", { replace: true }),
    [navigate]
  );
  const handleRouteStake = useCallback(
    () => navigate("/stake", { replace: true }),
    [navigate]
  );
  const handleRouteLiquidity = useCallback(
    () => navigate("/liquidity", { replace: true }),
    [navigate]
  );
  const handleRouteVest = useCallback(
    () => navigate("/vest", { replace: true }),
    [navigate]
  );

  const handleRouteZGND = useCallback(
    () => navigate("/zgnd", { replace: true }),
    [navigate]
  );

  const sm = useMediaQuery("(max-width : 900px)");

  return (
    <div>
      {!sm ? (
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light foi-navbar">
            <a className="navbar-brand">
              <img
                className="logo"
                src={require("../../images/logoGND.png")}
                alt="GND"
                onClick={handleRouteHome}
              />
            </a>
            {/* <button className="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#collapsibleNavId" aria-controls="collapsibleNavId" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button> */}
            <div className="collapse navbar-collapse" id="collapsibleNavId">
              <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                <li className="nav-item active">
                  <a className="nav-link" onClick={handleRouteHome}>
                    Home <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={handleRouteDashboard}>
                    Dashboard
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={handleRouteStake}>
                    Stake
                  </a>
                </li>
                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="pagesMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pages</a>
                                    <div className="dropdown-menu" aria-labelledby="pagesMenu">
                                        <a className="dropdown-item" href="blog.html">Blog</a>
                                        <a className="dropdown-item" href="login.html">Login</a>
                                        <a className="dropdown-item" href="register.html">Register</a>
                                        <a className="dropdown-item" href="faq.html">FAQ</a>
                                        <a className="dropdown-item" href="404.html">404</a>
                                        <a className="dropdown-item" href="careers.html">Careers</a>
                                        <a className="dropdown-item" href="blog-single.html">Single blog</a>
                                        <a className="dropdown-item" href="privacy-policy.html">Privacy policy</a>
                                    </div>
                                </li> */}
                <li className="nav-item">
                  <a className="nav-link" onClick={handleRouteLiquidity}>
                    Liquidity
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="blank"
                    href="http://farm.gndprotocol.com"
                  >
                    Farm
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="blank"
                    href="http://geth.gndprotocol.com"
                  >
                    gETH
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" onClick={handleRouteVest}>
                    Vest&Lock
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={handleRouteZGND}>
                    zGND
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav mt-2 mt-lg-0">
                <li className="nav-item mr-2 mb-3 mb-lg-0">
                  <Button
                    type={"primary"}
                    width={"162px"}
                    height={"36px"}
                    onClick={() => (account ? disconnect() : onConnect())}
                    account={account}
                  >
                    {ellipsis}
                  </Button>
                </li>
              </ul>
              {/* <ul className="navbar-nav mt-2 mt-lg-0">
                                <li className="nav-item mr-2 mb-3 mb-lg-0">
                                    <a className="btn btn-secondary" href="register.html">Sign up</a>
                                </li>
                                <li className="nav-item">
                                    <a className="btn btn-secondary" href="login.html">Login</a>
                                </li>
                            </ul> */}
            </div>
          </nav>
        </div>
      ) : (
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light foi-navbar">
            <a className="navbar-brand">
              <img
                className="logo"
                src={require("../../images/logoGND.png")}
                alt="GMD"
                onClick={handleRouteHome}
              />

              <Hamburger
                color={color}
                setNotification={setNotification}
                connectEnabled={connectEnabled}
              />
            </a>
          </nav>
        </div>
      )}
    </div>
  );
}

export default Topbar;

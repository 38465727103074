/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { multicall } from "../utils/contracts";
import axios from "axios";
import _ from "lodash";
import { UNIV3_ADDR, UNIV3_LP_ADDR } from "../abis/address";
import pools from "../tokens/Pools";
import UniswapMathABI from "../abis/UniswapMathABI.json";
import UniV3LPABI from "../abis/UniV3LPABI.json";
import { BigNumber, ethers } from "ethers";
import IUniswapV3Pool from "../abis/IUniswapV3Pool.json";
import { Pool } from "@uniswap/v3-sdk/";
import { Token } from "@uniswap/sdk-core";
const provider = new ethers.providers.JsonRpcProvider(
  "https://arb1.arbitrum.io/rpc"
);

const defaultVal = {
  tokenAmounts: [],
  tokenAddress: [],
  tokenRates: [],
  tokenRatios: [],
  lpPrices: [],
  price: 0,
};

export const PoolsInfoContext = React.createContext(defaultVal);

export default function usePoolsInfo() {
  return React.useContext(PoolsInfoContext);
}
let dataid = null;

export function PoolsInfoProvider({ children }) {
  const [price, setPrice] = useState(0);
  const [tokenAmounts, setAmounts] = useState([]);
  const [tokenRates, setRates] = useState([]);
  const [tokenAddress, setTokenAddress] = useState([]);
  const [tokenRatios, setRatios] = useState([]);
  const [lpPrices, setLPPrices] = useState([]);

  //add to database
  async function getAmountsFromDB() {
    try {
      const res = await fetch("https://gnd-backend.vercel.app/getAmounts", {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        credentials: "include",
      });
      const data = await res.json();
      let parsed = [];
      for (let i = 0; i < data.amounts.length; ++i) {
        parsed.push([
          BigNumber.from(data.amounts[i][0]),
          BigNumber.from(data.amounts[i][1]),
        ]);
        parsed[i].amount0 = BigNumber.from(data.amounts[i][2]);
        parsed[i].amount1 = BigNumber.from(data.amounts[i][3]);
      }
      setAmounts(parsed);
      let _rates = [];
      for (let i = 0; i < data.amounts.length - 1; i++) {
        let _rate =
          ethers.utils.formatEther(parsed[i][0]) /
          ethers.utils.formatEther(parsed[i][1]);
        if (i == 7) {
          _rate *= Math.pow(10, 10);
        }
        if (
          pools[i].tokenPriceIds[0] != "tether" &&
          pools[i].tokenPriceIds[1] == "usd-coin"
        ) {
          console.log("1side usdc");
          console.log(pools[i].tokenPriceIds[0]);
          _rate /= Math.pow(10, 12);
          console.log(_rate);
        }
        _rates.push(_rate);
      }
      setRates(_rates);
      console.log("amounts length: " + data.amounts.length);
      console.log("amounts " + parsed);
      console.log("FETCHED AMOUNTS");
    } catch (err) {
      console.log("Error fetching:", err);
    }
  }

  async function getAmounts() {
    // fetch from database
    let calls = [];
    for (let i = 0; i < pools.length; i++) {
      calls.push({
        address: UNIV3_ADDR,
        name: "getReserveLiquidityNFT",
        params: [pools[i].pairAddress, pools[i].tokenID],
      });
    }
    let result = await multicall(UniswapMathABI, calls);
    // result = null;
    if (result) {
      // setRates(_rates);
      let parsed = [];
      for (let i = 0; i < pools.length; ++i) {
        parsed.push([
          result[i][0]._hex,
          result[i][1]._hex,
          result[i].amount0._hex,
          result[i].amount1._hex,
        ]); // _hex = big number
      }
      // add to database
      const data = {
        amounts: parsed,
      };
      const updateAmounts = async (data) => {
        const res = await fetch(
          "https://gnd-backend.vercel.app/updateAmounts",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            credentials: "include",
            body: JSON.stringify(data),
          }
        );
      };
      updateAmounts(data);
    }
  }

  async function getPoolImmutables(poolContract) {
    const immutables = {
      factory: await poolContract.factory(),
      token0: await poolContract.token0(),
      token1: await poolContract.token1(),
      fee: await poolContract.fee(),
      tickSpacing: await poolContract.tickSpacing(),
      maxLiquidityPerTick: await poolContract.maxLiquidityPerTick(),
    };
    return immutables;
  }

  async function getPoolState(poolContract) {
    const slot = await poolContract.slot0();
    const PoolState = {
      liquidity: await poolContract.liquidity(),

      sqrtPriceX96: slot[0],
      tick: slot[1],
      observationIndex: slot[2],
      observationCardinality: slot[3],
      observationCardinalityNext: slot[4],
      feeProtocol: slot[5],
      unlocked: slot[6],
    };
    return PoolState;
  }

  //add to database
  async function getLPTokenAddressFromDB() {
    // fetch from database
    try {
      const res = await fetch("https://gnd-backend.vercel.app/getAddress", {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        credentials: "include",
      });
      const data = await res.json();
      let parsed = [];
      for (let i = 0; i < data.address.length; ++i) {
        parsed.push([
          data.address[i][0],
          BigNumber.from(data.address[i][1]),
          data.address[i][2],
          data.address[i][3],
        ]);
        parsed[i].liquidity = BigNumber.from(data.address[i][4]);
        parsed[i].owner = data.address[i][5];
        parsed[i].token0 = data.address[i][6];
        parsed[i].token1 = data.address[i][7];
      }
      setTokenAddress(parsed);
      console.log("FETCHED ADDRESS");
    } catch (err) {
      console.log("Error fetching:", err);
    }
  }

  async function getLPTokenAddress() {
    let calls = [];
    for (let i = 0; i < pools.length; i++) {
      calls.push({
        address: UNIV3_LP_ADDR,
        name: "deposits",
        params: [pools[i].tokenID],
      });
    }
    let result = await multicall(UniV3LPABI, calls);
    if (result) {
      // add to database
      // setTokenAddress(result);
      let parsed = [];
      for (let i = 0; i < pools.length; ++i) {
        parsed.push([
          result[i][0],
          result[i][1]._hex,
          result[i][2],
          result[i][3],
          result[i].liquidity._hex,
          result[i].owner,
          result[i].token0,
          result[i].token1,
        ]); // _hex = big number
      }
      const data = {
        address: parsed,
      };
      const updateAdress = async (data) => {
        const res = await fetch(
          "https://gnd-backend.vercel.app/updateAddress",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            credentials: "include",
            body: JSON.stringify(data),
          }
        );
        console.log("updateAdress");
      };
      updateAdress(data);
    }
  }

  //add to database
  async function getTokenRatiosFromDB() {
    // fetch from database
    try {
      const res = await fetch("https://gnd-backend.vercel.app/getRatio", {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        credentials: "include",
      });
      const data = await res.json();
      let parsed = [];
      for (let i = 0; i < data.ratio.length; ++i) {
        parsed.push(BigNumber.from(data.ratio[i][0]));
      }
      setRatios(parsed);
      console.log(parseInt(parsed));
      console.log("FETCHED RATIO");
      console.log("ratio length: " + data.ratio.length);
    } catch (err) {
      console.log("Error fetching:", err);
    }
  }

  async function getTokenRatios() {
    let _tokenRatios = [];
    for (let i = 0; i < pools.length; i++) {
      const poolContract = new ethers.Contract(
        pools[i].pairAddress,
        IUniswapV3Pool,
        provider
      );
      const { sqrtPriceX96 } = await poolContract.slot0();
      const sqrtRatioX96 = sqrtPriceX96.mul(sqrtPriceX96);
      var ratio = sqrtRatioX96
        .mul(BigNumber.from(10).pow(18))
        .div(BigNumber.from(2).pow(192));
      if (
        pools[i].pairAddress == "0x2f5e87c9312fa29aed5c179e456625d79015299c"
      ) {
        ratio = ratio.div(Math.pow(10, 10));
      }
      if (
        pools[i].tokenPriceIds[0] != "tether" &&
        pools[i].tokenPriceIds[1] == "usd-coin"
      ) {
        console.log("arb usdc");
        ratio = ratio.mul(Math.pow(10, 12));
      }
      _tokenRatios.push(ratio);
    }
    if (_tokenRatios) {
      // save to database
      // setRatios(_tokenRatios);
      let parsed = [];
      for (let i = 0; i < pools.length; ++i) {
        parsed.push([_tokenRatios[i]._hex]);
      }
      const data = {
        ratio: parsed,
      };
      const updateRatio = async (data) => {
        const res = await fetch("https://gnd-backend.vercel.app/updateRatio", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          credentials: "include",
          body: JSON.stringify(data),
        });
      };
      updateRatio(data);
    }
  }

  async function fetchPriceWithRetry(url) {
    const response = await fetch(url);
    const data = await response.json();
    return data;
  }
  //add to database
  async function getPricesFromDB() {
    // fetch from database
    try {
      const res = await fetch("https://gnd-backend.vercel.app/getPrice", {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        credentials: "include",
      });
      const data = await res.json();
      let parsed = [];
      for (let i = 0; i < data.price.length; ++i) {
        parsed.push([
          parseFloat(data.price[i][0]),
          parseFloat(data.price[i][1]),
        ]);
      }
      setLPPrices(parsed);
      console.log("FETCHED PRICE");
      console.log("POOLS COUNT FETCHED:", parsed.length);
      console.log("PARSED", parsed);
    } catch (err) {
      console.log("Error fetching:", err);
    }
  }

  async function fetchPrices() {
    let _poolCount = pools.length;
    let _lpPrices = [];
    for (let i = 0; i < _poolCount; i++) {
      if (pools[i].tokenPriceIds[1] === "gmusd") {
        let _price2 = await fetchPrice1(pools[i].tokenPriceIds[1]);
        let _price1 = _price2;

        if (pools[i].tokenPriceIds[0] != "gmusd") {
          let data1 = await fetchPriceWithRetry(
            `https://api.coingecko.com/api/v3/coins/${pools[i].tokenPriceIds[0]}?tickers=false&community_data=false&developer_data=false`
          );

          _price1 = data1 ? data1.market_data.current_price.usd : 0;
        }
        _lpPrices.push([parseFloat(_price1), _price2]);
      } else {
        let data = await fetchPriceWithRetry(
          `https://api.coingecko.com/api/v3/coins/${pools[i].tokenPriceIds[0]}?tickers=false&community_data=false&developer_data=false`
        );
        let _price1 = data ? data.market_data.current_price.usd : 0;

        let data2 = await fetchPriceWithRetry(
          `https://api.coingecko.com/api/v3/coins/${pools[i].tokenPriceIds[1]}?tickers=false&community_data=false&developer_data=false`
        );
        console.log("CALLED FETCHED PRICES", data2);
        let _price2 = data2 ? data2.market_data.current_price.usd : 0;
        _lpPrices.push([_price1, _price2]);
      }
    }
    if (_lpPrices) {
      console.log(_lpPrices);
      // add to database
      let parsed = [];
      // console.log("POOLS COUNT:", pools.length)
      for (let i = 0; i < pools.length; ++i) {
        parsed.push([_lpPrices[i][0].toString(), _lpPrices[i][1].toString()]);
      }
      const data = {
        price: parsed,
      };
      const updatePrice = async (data) => {
        const res = await fetch("https://gnd-backend.vercel.app/updatePrice", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          credentials: "include",
          body: JSON.stringify(data),
        });
      };
      console.log("POOLS COUNT UPDATE:", parsed.length);
      updatePrice(data);
    }
  }
  // pool address
  const poolAddress = "0x99db8DBA7b30aB3C6447C4388d81dcc27C5A8b61";
  const poolAddress1 = "0x3bB5690832AdED8D217D257583c189C7f0093fe9";

  async function fetchPrice1(tokenName) {
    let _poolAddress = tokenName === "gmusd" ? poolAddress1 : poolAddress;
    const poolContract = new ethers.Contract(
      _poolAddress,
      IUniswapV3Pool,
      provider
    );
    const immutables = await getPoolImmutables(poolContract);
    const state = await getPoolState(poolContract);
    const GND = new Token(42161, immutables.token0, 18, "GND", "GND Coin");
    const USDC = new Token(42161, immutables.token1, 6, "USDC", "USDC Coin");

    //create a pool
    const GND_USDC_POOL = new Pool(
      USDC,
      GND,
      immutables.fee,
      state.sqrtPriceX96.toString(),
      state.liquidity.toString(),
      state.tick
    );
    const price1 = await GND_USDC_POOL.token0Price;
    const price2 = await GND_USDC_POOL.token1Price;
    console.log("Price", price1.toFixed(2));
    if (tokenName !== "gmusd") setPrice(Number(price1.toFixed(10)));
    return price1.toFixed(2);
  }

  useEffect(() => {
    getAmountsFromDB();
    getLPTokenAddressFromDB();
    getTokenRatiosFromDB();
    fetchPrice1("");
    getAmounts();
    getLPTokenAddress();
    getTokenRatios();
    if (dataid) clearInterval(dataid);
    dataid = setInterval(() => {
      getAmounts();
      getTokenRatios();
      fetchPrice1("");
    }, 100000);
  }, []);

  return (
    <PoolsInfoContext.Provider
      value={{
        tokenAmounts,
        tokenAddress,
        tokenRates,
        tokenRatios,
        lpPrices,
        price,
        fetchPrices,
      }}
      children={children}
    />
  );
}

const tokensInfo = {
  USDC: {
    long: "USDC",
    short: "USDC",
    address: "",
    img: require("../images/logoUSDC.png"),
    color: "#348fab",
  },
  "USDC(new)": {
    long: "USDC(new)",
    short: "USDC(new)",
    address: "",
    img: require("../images/logoUSDC.png"),
    color: "#348fab",
  },
  "USDC(depeg)": {
    long: "USDC(depeg)",
    short: "USDC(depeg)",
    address: "",
    img: require("../images/logoUSDC.png"),
    color: "#348fab",
  },
  "USDC(range3)": {
    long: "USDC(range3)",
    short: "USDC(range3)",
    address: "",
    img: require("../images/logoUSDC.png"),
    color: "#348fab",
  },
  USDT: {
    long: "USDT",
    short: "USDT",
    address: "",
    img: require("../images/usdt.png"),
    color: "#348fab",
  },
  WETH: {
    long: "Ethereum",
    short: "WETH",
    address: "",
    img: require("../images/logoETH.png"),
    color: "#495c83",
  },
  "WETH(new)": {
    long: "WETH(new)",
    short: "WETH(new)",
    address: "",
    img: require("../images/logoETH.png"),
    color: "#495c83",
  },
  WBTC: {
    long: "Bitcoin",
    short: "WBTC",
    address: "",
    img: require("../images/logoBTC.png"),
    color: "#ff8d00",
  },
  GMD: {
    long: "GMD",
    short: "GMD",
    address: "",
    img: require("../images/logoGMD.png"),
    color: "#68BBE3",
  },
  GMX: {
    long: "GMX",
    short: "GMX",
    address: "",
    img: require("../images/logoGMX.png"),
    color: "#003060",
  },
  GND: {
    long: "GND",
    short: "GND",
    address: "",
    img: require("../images/logoGND.png"),
    color: "#003060",
  },

  gmUSD: {
    long: "gmUSD",
    short: "gmUSD",
    address: "",
    img: require("../images/gmusd.png"),
    color: "#003060",
  },

  gDAI: {
    long: "gDAI",
    short: "gDAI",
    address: "",
    img: require("../images/gdai.png"),
    color: "#003060",
  },

  gmdUSDC: {
    long: "gmdUSDC",
    short: "gmdUSDC",
    address: "",
    img: require("../images/logoUSDC.png"),
    color: "#003060",
  },

  BFR: {
    long: "Buffer",
    short: "BFR",
    address: "",
    img: require("../images/bfr.png"),
    color: "#003060",
  },

  VELA: {
    long: "Vela Exchange",
    short: "VELA",
    address: "",
    img: require("../images/vela.png"),
    color: "#003060",
  },

  GNS: {
    long: "Gains Network",
    short: "GNS",
    address: "",
    img: require("../images/gns.png"),
    color: "#003060",
  },
  MAGIC: {
    long: "MAGIC",
    short: "MAGIC",
    address: "",
    img: require("../images/magic.png"),
    color: "#003060",
  },
  PENDLE: {
    long: "PENDLE",
    short: "PENDLE",
    address: "",
    img: require("../images/pendle.png"),
    color: "#003060",
  },
  RDNT: {
    long: "Radiant",
    short: "RDNT",
    address: "",
    img: require("../images/rdnt.png"),
    color: "#003060",
  },
  CHR: {
    long: "Chronos",
    short: "CHR",
    address: "",
    img: require("../images/chr.png"),
    color: "#003060",
  },
  ARB: {
    long: "Arbitrum",
    short: "ARB",
    address: "",
    img: require("../images/arb.png"),
    color: "#003060",
  },
  "ARB(range2)": {
    long: "Arbitrum",
    short: "ARB(range2)",
    address: "",
    img: require("../images/arb.png"),
    color: "#003060",
  },
  DAI: {
    long: "DAI",
    short: "DAI",
    address: "",
    img: require("../images/dai.png"),
    color: "#003060",
  },
  YFX: {
    long: "YFX",
    short: "YFX",
    address: "",
    img: require("../images/yfx.png"),
    color: "#003060",
  },
  PEPE: {
    long: "PEPE",
    short: "PEPE",
    address: "",
    img: require("../images/pepe.png"),
    color: "#003060",
  },
  "USD+": {
    long: "USD+",
    short: "USD+",
    address: "",
    img: require("../images/usd+.png"),
    color: "#003060",
  },
  "DAI+": {
    long: "DAI+",
    short: "DAI+",
    address: "",
    img: require("../images/dai+.png"),
    color: "#003060",
  },
  LOTUS: {
    long: "LOTUS",
    short: "LOTUS",
    address: "",
    img: require("../images/lotus.png"),
    color: "#003060",
  },
  GHA: {
    long: "GHA",
    short: "GHA",
    address: "",
    img: require("../images/gha.png"),
    color: "#003060",
  },
  DMT: {
    long: "DMT",
    short: "DMT",
    address: "",
    img: require("../images/dmt.png"),
    color: "#003060",
  },
  WAR: {
    long: "WAR",
    short: "WAR",
    address: "",
    img: require("../images/war.png"),
    color: "#003060",
  },
  zGND: {
    long: "zGND",
    short: "zGND",
    address: "",
    img: require("../images/zgnd.png"),
    color: "#003060",
  },
  gETH: {
    long: "gETH",
    short: "gETH",
    address: "",
    img: require("../images/gETH.png"),
    color: "#003060",
  },
  wstETH: {
    long: "wstETH",
    short: "wstETH",
    address: "",
    img: require("../images/wstETH.png"),
    color: "#003060",
  },
};

export default tokensInfo;

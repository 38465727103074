/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import styled from "styled-components";
import Button from "../../components/Button";
import OTCModal from "../../components/OTCModal";
import GMDStakingModal2 from "../../components/esGMDStakingModal";
import LockModal from "../../components/lockModal";
import { useState } from "react";
import useTokenInfo from "../../hooks/useTokenInfo";
import VestInfo from "../../hooks/VestContext";
import { numberWithCommas, figureError } from "../../utils/functions";
import { useAddress, useWeb3Context } from "../../hooks/web3Context";
import useGMDStakingInfo from "../../hooks/useGMDStakingInfo";
import {
  getGMDXGNDContract,
  getXGNDContract,
  getCashBackContract,
  getOTCContract,
} from "../../utils/contracts";
import { ethers } from "ethers";
import Topbar from "../../components/Topbar/Topbar";
import TopbarDapp from "../../components/Topbar2/Topbar";
import CountDown from "../../components/Countdown";
const Vest = ({ setNotification }) => {
  const { price, pool } = useTokenInfo();
  const { mintAccountData, getTimestampInSeconds } = VestInfo();
  const PosNumbers = mintAccountData.VestPositions;
  const Positions = mintAccountData.positions;
  const {
    allowance,
    esGMDallowance,
    esGMDbalance,
    balance,
    totalStaked,
    totalesGMDStaked,

    totalRP,
    totalRPEs,
    CASHBACK,
    lockAllowance,
    fetchStakingAccountData,
    MaxCASHBACK,
    xGNDCashBack,
    swapRate,
    xGNDtotalBuyBack,
    BuyBackBalance,
  } = useGMDStakingInfo();

  const account = useAddress();
  const { connect, provider } = useWeb3Context();

  function onConnect() {
    connect().then((msg) => {
      if (msg.type === "error") {
        setNotification(msg);
      }
    });
  }

  const [open, setOpen] = useState(false);
  const [type, setType] = useState(1);
  const [open2, setOpen2] = useState(false);
  const [type2, setType2] = useState(1);
  const [open3, setOpen3] = useState(false);
  const [type3, setType3] = useState(1);

  const [amount, setAmount] = useState(0);
  const [maxPressed, setMaxPresssed] = useState(false);
  const [pending, setPending] = useState(false);
  const [amount2, setAmount2] = useState(0);
  const [maxPressed2, setMaxPresssed2] = useState(false);
  const [pending2, setPending2] = useState(false);
  const [amount3, setAmount3] = useState(0);
  const [maxPressed3, setMaxPresssed3] = useState(false);
  const [pending3, setPending3] = useState(false);

  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const getBalance = (amount, decimals = 18) => {
    return numberWithCommas((amount / Math.pow(10, decimals)).toFixed(5));
  };
  const getBalanceUSD = (amount, price, decimals = 18) => {
    return numberWithCommas(
      ((price * amount) / Math.pow(10, decimals)).toFixed(5)
    );
  };
  const getStakedUSD = price * (totalStaked + totalRP);
  const getStakedUSDEs = price * (totalesGMDStaked + totalRPEs);

  const onConfirm = async () => {
    setPending(true);
    try {
      const XGNDContract = getXGNDContract(provider.getSigner());
      let estimateGas, tx;
      if (type === 2) {
        estimateGas = await XGNDContract.estimateGas.vest(
          maxPressed ? balance : ethers.utils.parseEther(amount)
        );
      } else {
        estimateGas = await XGNDContract.estimateGas.vestHalf(
          maxPressed ? balance : ethers.utils.parseEther(amount)
        );
      }
      console.log(estimateGas.toString());

      const ttx = {
        gasLimit: Math.ceil(estimateGas.toString() * 1.2),
      };
      if (type === 2) {
        tx = await XGNDContract.vest(
          maxPressed ? balance : ethers.utils.parseEther(amount),
          ttx
        );
      } else {
        tx = await XGNDContract.vestHalf(
          maxPressed ? balance : ethers.utils.parseEther(amount),
          ttx
        );
      }
      await tx.wait();
      fetchStakingAccountData();
    } catch (error) {
      console.log(error);
      figureError(error, setNotification);
    }
    setPending(false);
  };

  const onConfirm2 = async () => {
    setPending2(true);
    try {
      const CashBackContract = getCashBackContract(provider.getSigner());
      let estimateGas, tx;

      estimateGas = await CashBackContract.estimateGas.lockDiscount(
        maxPressed ? esGMDbalance : ethers.utils.parseEther(amount2)
      );

      console.log(estimateGas.toString());

      const ttx = {
        gasLimit: Math.ceil(estimateGas.toString() * 1.2),
      };

      tx = await CashBackContract.lockDiscount(
        maxPressed ? esGMDbalance : ethers.utils.parseEther(amount2),
        ttx
      );

      await tx.wait();
      fetchStakingAccountData();
    } catch (error) {
      console.log(error);
      figureError(error, setNotification);
    }
    setPending2(false);
  };

  const onConfirm3 = async () => {
    setPending3(true);
    try {
      const OTCContract = getOTCContract(provider.getSigner());
      let estimateGas, tx;

      estimateGas = await OTCContract.estimateGas.otcSwap(
        maxPressed3 ? balance : ethers.utils.parseEther(amount3)
      );

      console.log(estimateGas.toString());

      const ttx = {
        gasLimit: Math.ceil(estimateGas.toString() * 1.2),
      };

      tx = await OTCContract.otcSwap(
        maxPressed3 ? balance : ethers.utils.parseEther(amount3),
        ttx
      );

      await tx.wait();
      fetchStakingAccountData();
    } catch (error) {
      console.log(error);
      figureError(error, setNotification);
    }
    setPending3(false);
  };

  const onClaim = async (id) => {
    setPending(true);
    try {
      const XGNDContract = getXGNDContract(provider.getSigner());
      let estimateGas, tx;
      estimateGas = await XGNDContract.estimateGas.claim(id);
      console.log(estimateGas.toString());

      const ttx = {
        gasLimit: Math.ceil(estimateGas.toString() * 1.2),
      };
      tx = await XGNDContract.claim(id);
      await tx.wait();
      fetchStakingAccountData();
    } catch (error) {
      console.log(error);
      figureError(error, setNotification);
    }
    setPending(false);
  };

  return (
    <div>
      <TopbarDapp
        color="white"
        connectEnabled={true}
        style={{ paddingTop: "24px" }}
      />
      <StyledContainer>
        <GMDStakingModal2
          open={open}
          setOpen={setOpen}
          maxPressed={maxPressed}
          setMaxPressed={setMaxPresssed}
          onConfirm={onConfirm}
          pending={pending}
          setPending={setPending}
          setNotification={setNotification}
          max={balance / Math.pow(10, 18)}
          amount={amount}
          setAmount={setAmount}
          type={type}
        />

        <LockModal
          open={open2}
          setOpen={setOpen2}
          maxPressed={maxPressed2}
          setMaxPressed={setMaxPresssed2}
          onConfirm={onConfirm2}
          pending={pending2}
          setPending={setPending2}
          setNotification={setNotification}
          max={esGMDbalance / Math.pow(10, 18)}
          amount={amount2}
          setAmount={setAmount2}
          type={type2}
        />

        <OTCModal
          open={open3}
          setOpen={setOpen3}
          maxPressed={maxPressed3}
          setMaxPressed={setMaxPresssed3}
          onConfirm={onConfirm3}
          pending={pending3}
          setPending={setPending3}
          setNotification={setNotification}
          max={balance / Math.pow(10, 18)}
          amount={amount3}
          setAmount={setAmount3}
          type={type3}
        />
        <Box fontSize={"34px"} mb={"8px"} fontWeight={"bold"}>
          Lock GND or Vest xGND
        </Box>

        <Button
          type={"secondary"}
          width={"120px"}
          height={"30px"}
          onClick={toggleDetails}
          mb={"10px"}
        >
          {showDetails ? "Hide" : "Read more"}
        </Button>
        {showDetails && (
          <>
            <Box fontSize={"18px"} fontWeight={"200"} mt={"1px"}>
              1. Vest 1 xGND 20 days to receive 0.5 GND or 200 days to receive 1
              GND. You can also OTC immediately to receive 0.4 GND for 1 xGND
            </Box>
            <Box fontSize={"18px"} fontWeight={"200"} mt={"10px"}>
              2. Dutch Auction Cashback Machine Integrated. Lock more than 1 GND
              to be eligible for cashback xGND from treasury.
            </Box>
            <Box fontSize={"18px"} fontWeight={"200"} mt={"10px"}>
              3. Cash back rate increases 1% every 20 minutes if there's no
              lock. Rate decreases by 1% every time there is an eligible lock.
              Plan your strategy accordingly to get the best rate!
            </Box>
          </>
        )}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          marginTop={"20px"}
        >
          <Panel>
            <PanelHeader justifyContent={"center"}>
              <Box>
                <CoinSVG
                  justifyContent={"center"}
                  style={{
                    background: `url(/icons/logo.png)`,
                    backgroundSize: "100% 100%",
                    width: "75px",
                    height: "70px",
                  }}
                />
              </Box>
            </PanelHeader>

            <Divider />
            <PanelBody>
              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Price</Box>
                <Box>${price.toFixed(2)}</Box>
              </Box>
              <Box>
                <Box color={"rgba(255, 255, 255, 0.7)"}>xGND balance</Box>
                <Box>
                  {getBalance(balance)} xGND (${getBalanceUSD(balance, price)})
                </Box>
              </Box>
              <Box alignItems={"center"} mb={"5px!important"}>
                <Box color={"rgba(255, 255, 255, 0.7)"}>GND balance</Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Box>
                    {getBalance(esGMDbalance)} GND ($
                    {getBalanceUSD(esGMDbalance, price)})
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box alignItems={"center"} mb={"5px!important"}>
                <Box color={"rgba(255, 255, 255, 0.7)"}>OTC xGND rate</Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Box>{swapRate}% of amount</Box>
                </Box>
              </Box>

              <Box alignItems={"center"} mb={"5px!important"}>
                <Box color={"rgba(255, 255, 255, 0.7)"}>
                  total xGND reacquired
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Box>
                    {" "}
                    {xGNDtotalBuyBack.toFixed(5)} xGND ($
                    {numberWithCommas((xGNDtotalBuyBack * price).toFixed(5))})
                  </Box>
                </Box>
              </Box>
              <Box alignItems={"center"} mb={"5px!important"}>
                <Box color={"rgba(255, 255, 255, 0.7)"}>OTC GND available</Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Box>
                    {" "}
                    {BuyBackBalance.toFixed(5)} GND ($
                    {numberWithCommas((BuyBackBalance * price).toFixed(5))})
                  </Box>
                </Box>
              </Box>
            </PanelBody>
            <Divider />

            <Box display={"flex"} justifyContent={"center"}>
              {!account ? (
                <Button
                  type={"primary"}
                  width={"136px"}
                  height={"36px"}
                  onClick={() => onConnect()}
                >
                  Connect Wallet
                </Button>
              ) : (
                <>
                  <Button
                    type={"primary"}
                    width={"165px"}
                    height={"36px"}
                    disabled={pending}
                    onClick={() => {
                      setOpen3(true);
                      setType(1);
                    }}
                  >
                    {"OTC xGND buy back"}
                  </Button>
                  <Button
                    type={"primary"}
                    width={"165px"}
                    height={"36px"}
                    disabled={pending}
                    onClick={() => {
                      setOpen(true);
                      setType(1);
                    }}
                  >
                    Vest xGND 20 days
                  </Button>
                  <Button
                    type={"primary"}
                    width={"167px"}
                    height={"36px"}
                    disabled={pending}
                    onClick={() => {
                      setOpen(true);
                      setType(2);
                    }}
                  >
                    Vest xGND 200 days
                  </Button>
                </>
              )}
            </Box>
            <Divider />
            <PanelBody>
              <Box alignItems={"center"} mb={"5px!important"}>
                <Box color={"rgba(255, 255, 255, 0.7)"}>
                  Current Cashback Rate
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Box>{CASHBACK}%</Box>
                </Box>
              </Box>
              <Box alignItems={"center"} mb={"5px!important"}>
                <Box color={"rgba(255, 255, 255, 0.7)"}>Max Cashback Rate</Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Box>{MaxCASHBACK}%</Box>
                </Box>
              </Box>
              <Box alignItems={"center"} mb={"5px!important"}>
                <Box color={"rgba(255, 255, 255, 0.7)"}>
                  remaining xGND for Cashback
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Box>
                    {" "}
                    {xGNDCashBack.toFixed(5)} xGND ($
                    {numberWithCommas((xGNDCashBack * price).toFixed(5))})
                  </Box>
                </Box>
              </Box>
            </PanelBody>
            <Divider />

            <Box display={"flex"} justifyContent={"center"}>
              {!account ? (
                <Button
                  type={"primary"}
                  width={"136px"}
                  height={"36px"}
                  onClick={() => onConnect()}
                >
                  Connect Wallet
                </Button>
              ) : (
                <>
                  <Button
                    type={"primary"}
                    width={"165px"}
                    height={"36px"}
                    disabled={pending}
                    onClick={() => {
                      setOpen2(true);
                      setType(1);
                    }}
                  >
                    {"Lock GND -> xGND"}
                  </Button>
                </>
              )}
            </Box>
          </Panel>
          <Panel mt={"25px"}>
            <Box font={"bold"} textAlign={"center"} mb={"15px"}>
              Your Vest Positions
            </Box>
            <PanelBody>
              {Positions.map((data, i) => {
                if (data[0] == 0) return null;
                console.log(data[0]);
                return (
                  <Box alignItems={"center"} mb={"5px!important"}>
                    <Box>
                      {data[0] / Math.pow(10, 18)} GND ($
                      {getBalanceUSD(data[0] / Math.pow(10, 18), price, 0)})
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                    >
                      {[data[1] + data[2] - getTimestampInSeconds()] > 0 ? (
                        <Box>
                          {" "}
                          <CountDown
                            date={[data[1] + data[2] - getTimestampInSeconds()]}
                          />
                        </Box>
                      ) : (
                        <Button
                          type={"secondary"}
                          width={"100%"}
                          height={"47px"}
                          disabled={pending}
                          onClick={() => onClaim(i)}
                        >
                          Claim
                        </Button>
                      )}
                    </Box>
                  </Box>
                );
              })}
            </PanelBody>
          </Panel>
        </Box>
      </StyledContainer>
    </div>
  );
};

const CoinSVG = styled(Box)`
  width: 40px;
  height: 40px;
`;

const Panel = styled(Box)`
  padding: 15px 15px 18px;
  border: 1px solid #61b8b5;
  border-radius: 4px;
  font-size: 15px;
  background: transparent;
  width: 100%;

  @media screen and (max-width: 900px) {
    max-width: 100%;
  }
  margin-bottom: 15px;
`;
const PanelHeader = styled(Box)`
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
`;

const PanelBody = styled(Box)`
  > div {
    display: flex;
    justify-content: space-between;
    line-height: 130%;
    margin-top: 8px;
  }
  > div:first-child {
    margin: 0;
  }
`;

const Divider = styled(Box)`
  height: 1px;
  background: #61b8b5;
  margin: 10.5px -15px;
`;

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  max-width: 600px;
  padding-left: 32px;
  padding-right: 32px;
  margin: 0 auto;
  letter-spacing: 0.5px;
  padding-bottom: 100px;
  @media screen and (max-width: 600px) {
    padding-left: 22px;
    padding-right: 22px;
  }
`;

export default Vest;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { getMulticallContract, multicall } from "../utils/contracts";
import ValutABI from "../abis/ValutABI.json";
import ERC20ABI from "../abis/ERC20ABI.json";
import CASHBACKABI from "../abis/CASHBACKABI.json";
import OTCABI from "../abis/OTCABI.json";
import GMDStakingABI from "../abis/GMDStakingABI.json";
import {
  GMD_ADDR,
  esGMD_ADDR,
  GMD_STAKING_ADDR,
  GMD_STAKING_ADDR2,
  MINT_ADDR,
  USDC_ADDR,
  VAULT_ADDR,
  xGND_ADDR,
  GND_ADDR,
  CASHBACK_ADDR,
  OTC_ADDR,
  ZGMD_ADDR,
  ZGMD_STAKING_ADDR,
} from "../abis/address";
import { useAddress } from "./web3Context";
import { ethers } from "ethers";

const defaultVal = {
  allowance: 0,
  esGMDallowance: 0,
  balance: 0,
  esGMDbalance: 0,
  totalStaked: 0,
  totalesGMDStaked: 0,
  withdrawable: false,
  apy: 0,
  apyEs: 0,
  reward: 0,
  rewardEs: 0,
  reward2: 0,
  RPreward: 0,
  RPrewardEs: 0,
  stakedRP: 0,
  stakedRPEs: 0,
  stakedAmount: 0,
  totalRevenue: 0,
  poolPercent: 0,
  poolPercentEs: 0,
  totalRP: 0,
  totalRPEs: 0,
  boostPercent: 0,
  boostPercentEs: 0,
  stakedEsAmount: 0,
  VestAmount: 0,
  zGNDbalance: 0,
  fetchStakingData: () => {},
  fetchStakingAccountData: () => {},
};

export const GMDStakingInfoContext = React.createContext(defaultVal);

export default function useGMDStakingInfo() {
  return React.useContext(GMDStakingInfoContext);
}
let dataid = null,
  accountid = null;

export function GMDStakingInfoProvider({ children }) {
  const account = useAddress();
  const [allowance, setAllowance] = useState(false);
  const [OTCAllowance, setOTCAllowance] = useState(false);
  const [balance, setBalance] = useState(0);
  const [esGMDallowance, setesGMDAllowance] = useState(false);
  const [esGMDbalance, setesGMDBalance] = useState(0);
  const [totalStaked, setTotalStaked] = useState(0);
  const [totalesGMDStaked, setTotalesGMDStaked] = useState(0);
  const [withdrawable, setWithdrawable] = useState(false);
  const [apy, setAPY] = useState(0);
  const [VestAmount, setVestAmount] = useState(0);
  const [apyEs, setAPYEs] = useState(0);
  const [boostPercent, setboostPercent] = useState(0);
  const [boostPercentEs, setboostPercentEs] = useState(0);
  const [totalRP, settotalRP] = useState(0);
  const [totalRPEs, settotalRPEs] = useState(0);
  const [reward, setReward] = useState(0);
  const [rewardEs, setRewardEs] = useState(0);
  const [poolPercent, setpoolPercent] = useState(0);
  const [poolPercentEs, setpoolPercentEs] = useState(0);
  const [RPreward, setRPReward] = useState(0);
  const [stakedRP, setstakedRP] = useState(0);
  const [RPrewardEs, setRPRewardEs] = useState(0);
  const [stakedRPEs, setstakedRPEs] = useState(0);
  const [reward2, setReward2] = useState(0);
  const [stakedAmount, setStakedAmount] = useState(0);
  const [stakedEsAmount, setEsStakedAmount] = useState(0);
  const [totalRevenue, settotalRevenue] = useState(0);
  const [lockAllowance, setLockAllowance] = useState(0);
  const [CASHBACK, setCASHBACK] = useState(0);
  const [MaxCASHBACK, setMaxCASHBACK] = useState(0);
  const [xGNDCashBack, setxGNDCASHBACK] = useState(0);
  const [swapRate, setSwapRate] = useState(0);
  const [xGNDtotalBuyBack, setxGNDtotalBuyBack] = useState(0);
  const [BuyBackBalance, setBuyBackBalance] = useState(0);
  const [ZGNDbalance, setZGNDbalance] = useState(0);
  const [zGNDAllowance, setzGNDAllowance] = useState(0);
  const [zGNDstakingAllowance, setzGNDstakingAllowance] = useState(0);
  const [zGNDstakedAmount, setzGNDstakedAmount] = useState(0);
  async function fetchStakingData() {
    try {
      let calls = [
        {
          address: GMD_STAKING_ADDR,
          name: "poolInfo",
          params: [0],
        },
        {
          address: GMD_STAKING_ADDR,
          name: "withdrawable",
          params: [],
        },
        {
          address: GMD_STAKING_ADDR,
          name: "WETHPerSecond",
          params: [],
        },
        {
          address: GMD_STAKING_ADDR,
          name: "totalWETHdistributed",
          params: [],
        },
        {
          address: GMD_STAKING_ADDR,
          name: "totalAllocPoint",
          params: [],
        },
        {
          address: GMD_STAKING_ADDR2,
          name: "totalWETHdistributed",
          params: [],
        },
      ];
      let result = await multicall(GMDStakingABI, calls);
      if (result) {
        const revenue1 =
          result[3][0] == 0 ? 0.05 : result[3][0] / Math.pow(10, 18);
        const revenue2 =
          result[5][0] == 0 ? 0.05 : result[5][0] / Math.pow(10, 18);
        const PoolPercent = (result[0].allocPoint * 100) / result[4];
        setAPY(
          result[2] == 0
            ? (115 * Math.pow(10, 11) * PoolPercent) / Math.pow(10, 18)
            : (result[2] * PoolPercent) / Math.pow(10, 20)
        );
        console.log((result[2] * poolPercent) / Math.pow(10, 20));
        settotalRevenue(revenue1 + 7);
        setWithdrawable(result[1][0]);
        settotalRP(result[0].totalRP);
        setpoolPercent(PoolPercent);
      }
      let calls2 = [
        {
          address: GMD_STAKING_ADDR,
          name: "poolInfo",
          params: [1],
        },
        {
          address: GMD_STAKING_ADDR,
          name: "WETHPerSecond",
          params: [],
        },
        {
          address: GMD_STAKING_ADDR,
          name: "totalAllocPoint",
          params: [],
        },
      ];
      result = await multicall(GMDStakingABI, calls2);
      if (result) {
        const PoolPercent = (result[0].allocPoint * 100) / result[2];
        settotalRPEs(result[0].totalRP);
        setpoolPercentEs(PoolPercent);
        setAPYEs(
          result[1] == 0
            ? (115 * Math.pow(10, 11) * PoolPercent) / Math.pow(10, 18)
            : (result[1] * PoolPercent) / Math.pow(10, 20)
        );
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
    let calls2 = [
      {
        address: GMD_ADDR,
        name: "balanceOf",
        params: [GMD_STAKING_ADDR],
      },
      {
        address: esGMD_ADDR,
        name: "balanceOf",
        params: [GMD_STAKING_ADDR],
      },
      {
        address: xGND_ADDR,
        name: "balanceOf",
        params: [CASHBACK_ADDR],
      },
      {
        address: GND_ADDR,
        name: "balanceOf",
        params: [OTC_ADDR],
      },
    ];
    let result2 = await multicall(ERC20ABI, calls2);
    setTotalStaked(result2 ? result2[0][0] / Math.pow(10, 18) : 0);
    setTotalesGMDStaked(result2 ? result2[1][0] / Math.pow(10, 18) : 0);
    setxGNDCASHBACK(result2 ? result2[2][0] / Math.pow(10, 18) : 0);
    setBuyBackBalance(result2 ? result2[3][0] / Math.pow(10, 18) : 0);
    console.log(result2[2][0] / Math.pow(10, 18));

    calls2 = [
      {
        address: CASHBACK_ADDR,
        name: "viewCurrentCashbackRate",
        params: [],
      },
      {
        address: CASHBACK_ADDR,
        name: "maxCashbackRate",
        params: [],
      },
    ];

    result2 = await multicall(CASHBACKABI, calls2);
    console.log(result2[0][0] / 10);
    setCASHBACK(result2[0][0] / 10);
    setMaxCASHBACK(result2[1][0] / 10);

    calls2 = [
      {
        address: OTC_ADDR,
        name: "swapRate",
        params: [],
      },
      {
        address: OTC_ADDR,
        name: "totalXGND",
        params: [],
      },
    ];
    result2 = await multicall(OTCABI, calls2);
    console.log(result2[0][0] / 10);
    setSwapRate(result2[0][0] / 1);
    setxGNDtotalBuyBack(result2[1][0] / Math.pow(10, 18));
  }
  async function fetchStakingAccountData() {
    try {
      let calls = [
        {
          address: xGND_ADDR,
          name: "allowance",
          params: [account, GMD_STAKING_ADDR],
        },
        {
          address: xGND_ADDR,
          name: "balanceOf",
          params: [account],
        },
        {
          address: xGND_ADDR,
          name: "allowance",
          params: [account, xGND_ADDR],
        },
        {
          address: GND_ADDR,
          name: "balanceOf",
          params: [account],
        },
        {
          address: GND_ADDR,
          name: "allowance",
          params: [account, CASHBACK_ADDR],
        },
        {
          address: xGND_ADDR,
          name: "allowance",
          params: [account, OTC_ADDR],
        },

      ];
      let gndBalance = 0; 
      
      let result = await multicall(ERC20ABI, calls);
      if (result) {
        setAllowance(parseInt(result[0][0]) >= parseInt(result[1][0]));
        setBalance(result[1][0]);
        setesGMDAllowance(parseInt(result[2][0]) >= parseInt(result[1][0]));
        setesGMDBalance(result[3][0]);
        setLockAllowance(parseInt(result[4][0]) >= parseInt(result[3][0]));
        setOTCAllowance(parseInt(result[5][0]) >= parseInt(result[1][0]));
        gndBalance = result[3][0];
        console.log(parseInt(result[2][0]));
        console.log(parseInt(result[0][0]));
      }


      console.log(result);

      calls = [
        {
          address: ZGMD_ADDR,
          name: "balanceOf",
          params: [account],
        },
        {
        address: GND_ADDR,
          name: "allowance",
          params: [account, ZGMD_ADDR],
        },
        {
          address: ZGMD_ADDR,
            name: "allowance",
            params: [account, ZGMD_STAKING_ADDR],
          },
      ]

      result = await multicall(ERC20ABI, calls);
      if (result) {

      setZGNDbalance(result[0][0]);
      setzGNDAllowance(parseInt(result[1][0]) >= parseInt(gndBalance));
      setzGNDstakingAllowance(parseInt(result[2][0]) >= parseInt(result[0][0]));
      }

      calls = [
        {
          address: GMD_STAKING_ADDR,
          name: "userInfo",
          params: [0, account],
        },
        {
          address: GMD_STAKING_ADDR,
          name: "pendingWETH",
          params: [0, account],
        },
        {
          address: GMD_STAKING_ADDR,
          name: "pendingWETH",
          params: [0, account],
        },
        {
          address: GMD_STAKING_ADDR,
          name: "pendingRP",
          params: [0, account],
        },
        {
          address: ZGMD_STAKING_ADDR,
          name: "userInfo",
          params: [0, account],
        },
      ];

      result = await multicall(GMDStakingABI, calls);
      if (result) {
        const staked = result[0].amount;
        const zGNDstaked = result[4].amount;
        const rpStaked = result[0].RPamount;
        setReward(result[1][0] / Math.pow(10, 18));
        setReward2(result[2][0] / Math.pow(10, 18));
        setStakedAmount(staked);
        setstakedRP(rpStaked);
        setRPReward(result[3][0] / Math.pow(10, 18));
        setboostPercent(
          (staked / Math.pow(10, 16) + rpStaked / Math.pow(10, 16)) /
            (staked / Math.pow(10, 18))
        );
        setzGNDstakedAmount(zGNDstaked);
        setVestAmount(result[0].VestAmount);
      }
      console.log(result);

      calls = [
        {
          address: GMD_STAKING_ADDR,
          name: "userInfo",
          params: [1, account],
        },
        {
          address: GMD_STAKING_ADDR,
          name: "pendingWETH",
          params: [1, account],
        },
        {
          address: GMD_STAKING_ADDR,
          name: "pendingWETH",
          params: [1, account],
        },
        {
          address: GMD_STAKING_ADDR,
          name: "pendingRP",
          params: [1, account],
        },
        
      ];

      result = await multicall(GMDStakingABI, calls);
      if (result) {
        const staked = result[0].amount;
        const rpStaked = result[0].RPamount;
        setRewardEs(result[1][0] / Math.pow(10, 18));
        setEsStakedAmount(staked);
        setstakedRPEs(rpStaked);
        setRPRewardEs(result[3][0] / Math.pow(10, 18));
        setboostPercentEs(
          (staked / Math.pow(10, 16) + rpStaked / Math.pow(10, 16)) /
            (staked / Math.pow(10, 18))
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchStakingData();
    if (dataid) clearInterval(dataid);
    dataid = setInterval(() => {
      fetchStakingData();
    }, 20000);
  }, []);

  useEffect(() => {
    if (!account) return;
    fetchStakingAccountData();
    if (accountid) clearInterval(accountid);
    accountid = setInterval(() => {
      fetchStakingAccountData();
    }, 20000);
  }, [account]);

  return (
    <GMDStakingInfoContext.Provider
      value={{
        allowance,
        balance,
        esGMDallowance,
        esGMDbalance,
        totalStaked,
        totalesGMDStaked,
        withdrawable,
        apy,
        apyEs,
        poolPercent,
        poolPercentEs,
        reward,
        rewardEs,
        reward2,
        RPreward,
        RPrewardEs,
        totalRP,
        totalRPEs,
        stakedRP,
        stakedRPEs,
        boostPercent,
        boostPercentEs,
        stakedAmount,
        stakedEsAmount,
        totalRevenue,
        fetchStakingData,
        fetchStakingAccountData,
        VestAmount,
        lockAllowance,
        CASHBACK,
        MaxCASHBACK,
        xGNDCashBack,
        swapRate,
        xGNDtotalBuyBack,
        OTCAllowance,
        BuyBackBalance,
        ZGNDbalance,
        zGNDAllowance,
        zGNDstakingAllowance,
        zGNDstakedAmount
      }}
      children={children}
    />
  );
}

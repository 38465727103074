import "../../styles/style.css";
import { React, useState, useEffect } from "react";
import { ethers } from "ethers";
import TokenModal from "../../components/Liquidity/TokenModal";
import tokensInfo from "../../tokens/tokensInfo";
import TopbarDapp from "../../components/Topbar2/Topbar";
import pools from "../../tokens/Pools.json";
import { AiOutlineArrowDown } from "react-icons/ai";
import { useAddress, useWeb3Context } from "../../hooks/web3Context";
import usePoolsInfo from "../../hooks/usePoolsInfo";
import {
  getTokenContract,
  getUniswapMathContract,
} from "../../utils/contracts";
import { UNIV3_LP_ADDR, MAX } from "../../abis/address";
import UniV3LPABI from "../../abis/UniV3LPABI.json";
import { getUniV3LPContract } from "../../utils/contracts";
import { figureError } from "../../utils/functions";
import { parseEther } from "ethers/lib/utils";

function Liquidity({ setNotification }) {
  const [tokens, setTokens] = useState(["GMD", "WETH"]);
  const [modal, setModal] = useState(false);
  const [tokenIndex, setTokenIndex] = useState(-1);
  const [poolIndex, setPoolIndex] = useState(-1);
  const [action, setAction] = useState("add");
  const [amounts, setAmounts] = useState(["", ""]); // WHEN REMOVE LIQUIDITY, CHANGE THESE NUMBERS TO THE CALCULATED AMOUNTS BASED ON REMOVE AMOUNT
  const [removeAmount, setRemoveAmount] = useState("0");
  const [slippage, setSlippage] = useState("0.5");
  const [balances, setBalances] = useState(["0", "0"]);
  const [lpBalance, setLPBalance] = useState("0");
  const [maxlpBalance, setMaxLPBalance] = useState("0");
  const [disabled, setDisabled] = useState(false);
  const [approved, setApproved] = useState(false);

  const account = useAddress();
  const { connect, provider } = useWeb3Context();
  const { tokenRates, tokenAddress, tokenRatios } = usePoolsInfo();

  function onConnect() {
    connect().then((msg) => {
      if (msg.type === "error") {
        setNotification(msg);
      }
    });
  }

  const buttonText = account
    ? action === "remove"
      ? "Remove"
      : approved
      ? "Add"
      : "Approve"
    : "Connect Wallet";

  const handleTokenClick = (index) => {
    setModal(true);
    setTokenIndex(index);
  };

  const exitModal = () => {
    setModal(false);
  };

  const handleTokenSelect = async (token0, token1, index) => {
    setTokens([token0, token1]);
    setPoolIndex(index);
    if (!account) return;
    if (action === "add") {
      if (!tokenAddress.length) return;
      const lpTokenContract1 = await getTokenContract(
        tokenAddress[index].token0,
        provider.getSigner()
      );
      const balance1 = await lpTokenContract1.balanceOf(account);
      const lpTokenContract2 = await getTokenContract(
        tokenAddress[index].token1,
        provider.getSigner()
      );
      const balance2 = await lpTokenContract2.balanceOf(account);

      if (token0 == "WBTC") {
        setBalances([
          Math.floor(ethers.utils.formatEther(balance1) * 1000000000000000000) /
            100000000,
          Math.floor(ethers.utils.formatEther(balance2) * 100000000) /
            100000000,
        ]);
      } else if (token0 == "USDT" || token0 == "USD+") {
        setBalances([
          Math.floor(
            ethers.utils.formatEther(balance1) * 100000000000000000000
          ) / 100000000,
          Math.floor(
            ethers.utils.formatEther(balance2) * 100000000000000000000
          ) / 100000000,
        ]);
      } else if (pools[index].tokenPriceIds[1] == "usd-coin") {
        setBalances([
          Math.floor(ethers.utils.formatEther(balance1) * 100000000) /
            100000000,
          Math.floor(
            ethers.utils.formatEther(balance2) * 100000000000000000000
          ) / 100000000,
        ]);
      } else {
        setBalances([
          Math.floor(ethers.utils.formatEther(balance1) * 100000000) /
            100000000,
          Math.floor(ethers.utils.formatEther(balance2) * 100000000) /
            100000000,
        ]);
      }

      const _allownace1 = await lpTokenContract1.allowance(
        account,
        UNIV3_LP_ADDR
      );

      const _allowance2 = await lpTokenContract2.allowance(
        account,
        UNIV3_LP_ADDR
      );
      if (_allownace1 >= balance1 && _allowance2 >= balance2) {
        console.log("setapproved true");
        setApproved(true);
      }
    } else {
      const UniV3LPContract = await getUniV3LPContract();
      const _liquidityAddress = await UniV3LPContract.lps(pools[index].tokenID);
      const LPTokenContract = await getTokenContract(_liquidityAddress);
      const _lpBalance = await LPTokenContract.balanceOf(account);
      setMaxLPBalance(_lpBalance);
      setLPBalance(ethers.utils.formatEther(_lpBalance));
    }
  };

  const handleChangeAmount = (index, value) => {
    if (!tokenRates.length) return;
    let _opAmount = 0;
    if (index === 1) {
      if (tokenRates[poolIndex]) _opAmount = value / tokenRates[poolIndex];
      setDisabled(
        value > balances[0] || _opAmount > balances[1] ? true : false
      );
      setAmounts([value, _opAmount.toString()]);
      allowancecheck(value, _opAmount);
    } else {
      _opAmount = value * tokenRates[poolIndex];
      setDisabled(
        value > balances[1] || _opAmount > balances[0] ? true : false
      );
      setAmounts([_opAmount.toString(), value]);
      allowancecheck(_opAmount, value);
    }
  };

  const handleChangeRemoveAmount = async (value) => {
    setRemoveAmount(value);
    setDisabled(value > lpBalance);
    const UniswapMathContract = await getUniswapMathContract();
    const _amounts = await UniswapMathContract.getAmountsForLiquidityNFT(
      pools[poolIndex].pairAddress,
      pools[poolIndex].tokenID,
      ethers.utils.parseEther(value)
    );

    if (poolIndex == 7) {
      setAmounts([
        Math.floor(
          ethers.utils.formatEther(_amounts[0]) * 1000000000000000000
        ) / 100000000,
        Math.floor(ethers.utils.formatEther(_amounts[1]) * 100000000) /
          100000000,
      ]);
    } else if (pools[poolIndex].tokenPriceIds[0] == "tether") {
      setAmounts([
        Math.floor(
          ethers.utils.formatEther(_amounts[0]) * 100000000000000000000
        ) / 100000000,
        Math.floor(
          ethers.utils.formatEther(_amounts[1]) * 100000000000000000000
        ) / 100000000,
      ]);
    } else if (
      pools[poolIndex].tokenPriceIds[0] != "tether" &&
      pools[poolIndex].tokenPriceIds[1] == "usd-coin"
    ) {
      setAmounts([
        ethers.utils.formatEther(_amounts[0]),
        Math.floor(
          ethers.utils.formatEther(_amounts[1]) * 100000000000000000000
        ) / 100000000,
      ]);
    } else {
      setAmounts([
        ethers.utils.formatEther(_amounts[0]),
        ethers.utils.formatEther(_amounts[1]),
      ]);
    }
  };

  const allowancecheck = async (amount, amount2) => {
    try {
      const token0Contract = getTokenContract(
        tokenAddress[poolIndex].token0,
        provider.getSigner()
      );
      const _allownace1 = await token0Contract.allowance(
        account,
        UNIV3_LP_ADDR
      );

      const token1Contract = getTokenContract(
        tokenAddress[poolIndex].token1,
        provider.getSigner()
      );
      const _allowance2 = await token1Contract.allowance(
        account,
        UNIV3_LP_ADDR
      );
      if (
        parseInt(_allownace1) >= parseInt(parseEther(amount.toString())) &&
        parseInt(_allowance2) >= parseInt(parseEther(amount2.toString()))
      ) {
        console.log(parseInt(parseEther(amount.toString())));
        console.log(parseInt(parseEther(amount2.toString())));
        console.log(parseInt(_allownace1));
        console.log(parseInt(_allowance2));
        console.log("setapproved true");
        setApproved(true);
      } else {
        console.log("setapproved false");
        setApproved(false);
      }
    } catch (error) {
      console.log(error);
      figureError(error, setNotification);
    }
  };

  const doApprove = async () => {
    try {
      console.log(
        "tokenAddress",
        tokenAddress[poolIndex].token0,
        tokenAddress[poolIndex].token1
      );
      const token0Contract = getTokenContract(
        tokenAddress[poolIndex].token0,
        provider.getSigner()
      );
      const _allownace1 = await token0Contract.allowance(
        account,
        UNIV3_LP_ADDR
      );

      if (
        !(parseInt(_allownace1) >= parseInt(parseEther(amounts[0].toString())))
      ) {
        const tx1 = await token0Contract.approve(UNIV3_LP_ADDR, MAX);
        tx1.wait();
      }
      const token1Contract = getTokenContract(
        tokenAddress[poolIndex].token1,
        provider.getSigner()
      );
      const _allowance2 = await token1Contract.allowance(
        account,
        UNIV3_LP_ADDR
      );

      if (
        !(parseInt(_allowance2) >= parseInt(parseEther(amounts[1].toString())))
      ) {
        const tx2 = await token1Contract.approve(UNIV3_LP_ADDR, MAX);
        tx2.wait();
      }

      setApproved(true);
    } catch (error) {
      console.log(error);
      figureError(error, setNotification);
    }
  };

  const actionLiquidity = async () => {
    try {
      const UniV3LPContract = getUniV3LPContract(provider.getSigner());
      if (action === "add") {
        console.log("addaction", amounts[0], amounts[1]);
        console.log(
          "amountadd",
          ethers.utils.parseEther(parseFloat(amounts[0]).toFixed(17)),
          ethers.utils.parseEther(parseFloat(amounts[1]).toFixed(17))
        );
        var tx;
        if (pools[poolIndex].tokenID == 595054) {
          console.log("btc");
          tx = await UniV3LPContract.increaseLiquidityCurrentRange(
            pools[poolIndex].tokenID,
            parseInt(amounts[0] * Math.pow(10, 8)),
            ethers.utils.parseEther(parseFloat(amounts[1]).toFixed(17)),
            slippage * 1000
          );
        } else if (pools[poolIndex].tokenPriceIds[0] == "tether") {
          console.log("usdt");
          tx = await UniV3LPContract.increaseLiquidityCurrentRange(
            pools[poolIndex].tokenID,
            parseInt(
              (Math.floor(amounts[0] * 10000) / 10000) * Math.pow(10, 6)
            ),
            parseInt(
              (Math.floor(amounts[1] * 10000) / 10000) * Math.pow(10, 6)
            ),
            slippage * 1000
          );
        } else if (
          pools[poolIndex].tokenPriceIds[0] != "tether" &&
          pools[poolIndex].tokenPriceIds[1] == "usd-coin"
        ) {
          console.log("usdc arb");
          tx = await UniV3LPContract.increaseLiquidityCurrentRange(
            pools[poolIndex].tokenID,
            ethers.utils.parseEther(
              (Math.floor(amounts[0] * 1e17) / 1e17).toFixed(17)
            ),
            parseInt(
              (Math.floor(amounts[1] * 10000) / 10000) * Math.pow(10, 6)
            ),
            slippage * 1000
          );
        } else {
          tx = await UniV3LPContract.increaseLiquidityCurrentRange(
            pools[poolIndex].tokenID,
            ethers.utils.parseEther(
              (Math.floor(amounts[0] * 1e17) / 1e17).toFixed(17)
            ),
            ethers.utils.parseEther(
              (Math.floor(amounts[1] * 1e17) / 1e17).toFixed(17)
            ),
            slippage * 10000
          );
        }
        console.log(
          ethers.utils.parseEther(parseFloat(amounts[0] / 1e10).toFixed(17))
        );
        await tx.wait();
      } else {
        console.log(
          ethers.utils.parseEther(((removeAmount * 1e17) / 1e17).toFixed(17))
        );

        var tx;

        if (removeAmount == lpBalance) {
          console.log("max");
          tx = await UniV3LPContract.decreaseLiquidity(
            pools[poolIndex].tokenID,
            maxlpBalance,
            slippage * 1000
          );
        } else {
          tx = await UniV3LPContract.decreaseLiquidity(
            pools[poolIndex].tokenID,
            ethers.utils.parseEther(removeAmount),
            slippage * 1000
          );
        }

        await tx.wait();
      }
    } catch (error) {
      console.log(error);
      setTextAndDisplayDiv(error);
    }
  };
  function setTextAndDisplayDiv(error) {
    const div = document.getElementById("error"); // Get the div element
    const regex = /\"message\":\"(.+?)\"/;
    const match = regex.exec(error);
    const errorMessage = match ? match[1] : null;
    div.textContent = errorMessage; // Set the text content of the div
    div.style.color = "red";
    div.style.display = "block"; // Set the display property of the div to "block" to show it
  }

  useEffect(() => {
    setPoolIndex(-1);
    setRemoveAmount("0");
    setAmounts(["", ""]);
    setBalances(["0", "0"]);
    setSlippage("2");
  }, [action]);

  return (
    <div>
      <TopbarDapp
        color={"white"}
        connectEnabled={true}
        style={{ paddingTop: "24px" }}
      />
      <div className={modal ? "modal open" : "modal"}>
        <TokenModal
          exitModal={exitModal}
          handleTokenSelect={handleTokenSelect}
        />
      </div>

      <div className="container mt-5">
        <div
          className="row d-flex justify-content-start text-start"
          style={{ marginLeft: "0px" }}
        >
          <div
            className="col-12"
            style={{ fontSize: "34px", fontWeight: "bold" }}
          >
            Liquidity
          </div>
        </div>
        <div
          className="row d-flex justify-content-start text-start mt-4"
          style={{ marginLeft: "0px" }}
        >
          <div className="col-12" style={{ fontSize: "18px" }}>
            Add or remove your liquidity share in the pools.
          </div>
        </div>
        <div className="row d-flex justify-content-center mt-5">
          <div classsName="col-10">
            <div
              className="row d-flex justify-content-center align-items-center mt-2 mr-1 ml-1"
              style={{
                width: "400px",
                borderRadius: "4px",
                height: "50px",
                cursor: "pointer",
                border: "1px solid #61b8b5",
              }}
              onClick={handleTokenClick}
            >
              {poolIndex === -1 ? (
                <div>{"Please choose your pair."}</div>
              ) : (
                <>
                  <div className="col-1 justify-content-center">
                    <img
                      className="logo small"
                      style={{ display: "block", margin: "-5px" }}
                      src={tokensInfo[tokens[0]].img}
                    />
                  </div>
                  <div className="col-1 justify-content-center">
                    <img
                      className="logo small"
                      style={{ display: "block", margin: "-10px" }}
                      src={tokensInfo[tokens[1]].img}
                    />
                  </div>
                  <div className="col-8 text-center ml-2">
                    {tokensInfo[tokens[0]].short}/{tokensInfo[tokens[1]].short}
                    {"  "}
                    <span className="text-blue-600 text-2xl">
                      {pools[poolIndex].fees}
                      {"%"}
                    </span>
                  </div>
                </>
              )}
            </div>
            <div className="row d-flex mt-2">
              <div className="col-sm-12 col-md-5">
                <div className="row d-flex">
                  <div
                    className="col-12 text-start mt-2"
                    style={{ fontSize: "17px" }}
                  >
                    <span style={{ color: "#61b8b5" }}>Fees:</span>{" "}
                    {poolIndex === -1 ? "--" : pools[poolIndex].fees}%
                  </div>
                </div>
                <div className="row d-flex">
                  <div
                    className="col-12 text-start"
                    style={{ fontSize: "17px" }}
                  >
                    <span style={{ color: "#61b8b5" }}>MIN:&nbsp;</span>{" "}
                    {poolIndex === -1 ? "--" : pools[poolIndex]["lowBound"]}
                  </div>
                </div>
                <div className="row d-flex">
                  <div
                    className="col-12 text-start"
                    style={{ fontSize: "17px" }}
                  >
                    <span style={{ color: "#61b8b5" }}>MAX:</span>{" "}
                    {poolIndex === -1 ? "--" : pools[poolIndex]["highBound"]}
                  </div>
                </div>
                <div className="row d-flex">
                  <div
                    className="col-12 text-start"
                    style={{ fontSize: "17px" }}
                  >
                    <span style={{ color: "#61b8b5" }}>CURRENT:</span>{" "}
                    {poolIndex === -1 || !tokenRatios.length
                      ? "--"
                      : parseFloat(
                          ethers.utils.formatEther(tokenRatios[poolIndex])
                        ).toFixed(5)}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-7">
                <div className="row d-flex mt-2 justify-content-center">
                  <div className="col-12 justify-content-center text-center">
                    <button
                      className="btn"
                      style={{
                        color: "white",
                        fontWeight: "400",
                        fontSize: "15px",
                        width: "160px",
                        height: "35px",
                        paddingTop: "10px",
                        backgroundColor: `${
                          action === "add" ? "#61b8b5" : "grey"
                        }`,
                      }}
                      onClick={() => setAction("add")}
                    >
                      Add Liquidity
                    </button>
                  </div>
                </div>
                <div className="row d-flex mt-2 justify-content-center">
                  <div className="col-12 justify-content-center text-center">
                    <button
                      className="btn"
                      style={{
                        color: "white",
                        fontWeight: "400",
                        fontSize: "15px",
                        height: "35px",
                        paddingTop: "10px",
                        backgroundColor: `${
                          action === "remove" ? "#61b8b5" : "grey"
                        }`,
                      }}
                      onClick={() => setAction("remove")}
                    >
                      Remove Liquidity
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-center text-right mt-3">
          <div className="col-6" style={{ marginRight: "-30px" }}>
            Slippage:
            <input
              placeholder="0"
              onChange={(e) => setSlippage(e.target.value)}
              value={slippage}
              style={{
                color: "white",
                backgroundColor: "transparent",
                border: "1px solid grey",
                borderRadius: "4px",
                marginLeft: "5px",
                width: "50px",
                marginRight: "5px",
              }}
            ></input>
            %
          </div>
        </div>

        {poolIndex === -1 ? (
          <div style={{ paddingBottom: "270px" }}>
            {/* your content goes here */}
          </div>
        ) : (
          <div
            className="row d-flex justify-content-center mt-3"
            style={{ marginLeft: "0px" }}
          >
            <div className="col-6" style={{ minWidth: "400px" }}>
              {action === "remove" ? (
                <div>
                  <div
                    className="row d-flex justify-content-center"
                    style={{ border: "1px solid #61b8b5", borderRadius: "4px" }}
                  >
                    <div className="col-8">
                      <textarea
                        maxlength="20"
                        placeholder="0"
                        style={{
                          border: "0px",
                          borderRadius: "4px",
                          backgroundColor: "transparent",
                          outline: "none",
                          color: "white",
                          overflow: "hidden",
                          fontSize: "35px",
                          width: "100%",
                          height: "100%",
                          resize: "none",
                        }}
                        // onChange={(e) => setRemoveAmount(e.target.value)}
                        onChange={(e) =>
                          handleChangeRemoveAmount(e.target.value)
                        }
                        value={removeAmount}
                      ></textarea>
                    </div>
                    <div className="col-4">
                      <button
                        style={{
                          border: `0px solid ${tokensInfo[tokens[0]].color}`,
                          borderRadius: "4px",
                          backgroundColor: `transparent`,
                          height: "50%",
                          width: "190%",
                          minHeight: "40px",
                          minWidth: "150px",
                          marginLeft: "-70px",
                          marginTop: "0px",
                          color: "white",
                          cursor: "auto",
                        }}
                      >
                        <img
                          className="logo small"
                          src={tokensInfo[tokens[0]].img}
                          style={{ cursor: "auto" }}
                        />
                        <img
                          className="logo small"
                          src={tokensInfo[tokens[1]].img}
                          style={{ cursor: "auto", marginLeft: "-20px" }}
                        />
                        {tokensInfo[tokens[0]].short}/
                        {tokensInfo[tokens[1]].short}
                      </button>
                    </div>
                    <div
                      className="row"
                      style={{ width: "100%", marginTop: "-35px" }}
                    >
                      <div
                        className="col-12 mb-2"
                        style={{ textAlign: "right" }}
                      >
                        <span
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid grey",
                            borderRadius: "4px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => handleChangeRemoveAmount(lpBalance)}
                        >
                          Balance: {lpBalance} {tokensInfo[tokens[0]].short}/
                          {tokensInfo[tokens[1]].short}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3 mb-3">
                    <div className="col-12 text-center">
                      <AiOutlineArrowDown size={25} />
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              <div
                className="row d-flex justify-content-center"
                style={{ border: "1px solid #61b8b5", borderRadius: "4px" }}
              >
                <div className="col-9">
                  <textarea
                    maxlength="20"
                    style={{
                      border: "0px",
                      borderRadius: "4px",
                      backgroundColor: "transparent",
                      outline: "none",
                      color: "white",
                      overflow: "hidden",
                      fontSize: "35px",
                      width: "100%",
                      height: "100%",
                      resize: "none",
                    }}
                    placeholder="0"
                    onChange={(e) => {
                      handleChangeAmount(1, e.target.value);
                    }}
                    value={amounts[0]}
                  ></textarea>
                </div>
                <div className="col-3">
                  <button
                    style={{
                      border: `0px solid ${tokensInfo[tokens[0]].color}`,
                      borderRadius: "4px",
                      backgroundColor: `transparent`,
                      height: "50%",
                      width: "190%",
                      minHeight: "40px",
                      minWidth: "150px",
                      marginLeft: "-50px",
                      marginTop: "0px",
                      color: "white",
                      cursor: "auto",
                    }}
                  >
                    <img
                      className="logo small"
                      src={tokensInfo[tokens[0]].img}
                      style={{ marginRight: "10px", cursor: "auto" }}
                    />
                    {tokensInfo[tokens[0]].short}
                  </button>
                </div>
                {action === "add" ? (
                  <div
                    className="row"
                    style={{ width: "100%", marginTop: "-35px" }}
                  >
                    <div className="col-12 mb-2" style={{ textAlign: "right" }}>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          border: "1px solid grey",
                          borderRadius: "4px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          handleChangeAmount(1, balances[0]);
                        }}
                      >
                        Balance: {balances[0]} {tokensInfo[tokens[0]].short}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="row mt-1">
                <div className="col-12 text-center">
                  <p style={{ fontSize: "30px" }}>+</p>
                </div>
              </div>
              <div
                className="row d-flex justify-content-center mb-2"
                style={{ border: "1px solid #61b8b5", borderRadius: "4px" }}
              >
                <div className="col-9">
                  <textarea
                    maxlength="20"
                    style={{
                      border: "0px",
                      borderRadius: "4px",
                      backgroundColor: "transparent",
                      outline: "none",
                      color: "white",
                      overflow: "hidden",
                      fontSize: "35px",
                      width: "100%",
                      height: "100%",
                      resize: "none",
                    }}
                    placeholder="0"
                    onChange={(e) => handleChangeAmount(0, e.target.value)}
                    value={amounts[1]}
                  ></textarea>
                </div>
                <div className="col-3">
                  <button
                    style={{
                      border: `0px solid ${tokensInfo[tokens[1]].color}`,
                      borderRadius: "4px",
                      backgroundColor: `transparent`,
                      height: "50%",
                      width: "190%",
                      minHeight: "40px",
                      minWidth: "150px",
                      marginLeft: "-50px",
                      marginTop: "0px",
                      color: "white",
                      cursor: "auto",
                    }}
                  >
                    <img
                      className="logo small"
                      src={tokensInfo[tokens[1]].img}
                      style={{ marginRight: "10px", cursor: "auto" }}
                    />
                    {tokensInfo[tokens[1]].short}
                  </button>
                </div>
                {action === "add" ? (
                  <div
                    className="row"
                    style={{ width: "100%", marginTop: "-35px" }}
                  >
                    <div className="col-12 mb-2" style={{ textAlign: "right" }}>
                      <span
                        style={{
                          backgroundColor: "transparent",
                          border: "1px solid grey",
                          borderRadius: "4px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => handleChangeAmount(0, balances[1])}
                      >
                        Balance: {balances[1]} {tokensInfo[tokens[1]].short}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="row d-flex justify-content-center">
                {/* <div className="col-6 justify-content-center text-center">
                {action === "add" ? (
                  <button
                    className="btn btn-primary mb-4"
                    onClick={() => doApprove()}
                  >
                    {"approve"}
                  </button>
                ) : (
                  <></>
                )}
              </div> */}
                <div className="col-6 justify-content-center text-center">
                  <div
                    id="error"
                    className="justify-content-center text-center mb-2"
                  ></div>
                  <button
                    disabled={disabled}
                    className="btn btn-primary mb-4"
                    onClick={() =>
                      account
                        ? approved || action !== "add"
                          ? actionLiquidity()
                          : doApprove()
                        : onConnect()
                    }
                  >
                    {buttonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Liquidity;
